import { Component, Input, Inject, Injector } from "@angular/core";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { DmModalAbstract } from "../../../../../../common/abstraction/dm-modal.abstract";
import { DMLoggerService } from "../../../../../../common/services/dmlogger.service";
import { Components, AccessibilityConstants } from "../../../../../../common/application.constants";
import { SharedFunctionsService } from "../../../../../../common/services/sharedfunctions.service";
import { IEcifIoConsumption, IEcifIoConsumptionDetails, IIoDetailsWithProject } from "../../../../../../common/services/contracts/ecif-io-consumed-modal.contracts";
import { EcifIOEndDateExtensionModalComponent } from "./io-enddate-extension-modal.component";
import { IModal } from "../../../../../../components/modals/dm-modal-v2/dm-modal-v2.component";

@Component({
    selector: "app-ecif-io-consumed-modal",
    templateUrl: "./ecif-io-consumed-modal.component.html",
    styleUrls: ["./ecif-io-consumed-modal.component.scss"],
})

export class EcifIOConsumedModalComponent extends DmModalAbstract {
    @Input() public ecifIoConsumptionDetails: IEcifIoConsumption[];
    @Input() public currency: string;
    @Input() public isProjectContext: boolean;
    @Input() public wbsId: string;
    @Input() public wbsName: string;

    public isLoading: boolean = true; // todo remove these, they're already declared in parent component
    public showLoading: boolean = true;    
    public accessibilityConstants = AccessibilityConstants;
    public filteredIoList: IIoDetailsWithProject[];
    public isFilterCheckEnable: boolean = false;
    public modalContent: IModal;
    

    public constructor(
    @Inject(NgbActiveModal) activeModal: NgbActiveModal,
        @Inject(DMLoggerService) dmLogger: DMLoggerService,
        @Inject(SharedFunctionsService) private sharedFunctionsService: SharedFunctionsService,
        @Inject(NgbModal) private modalService: NgbModal,
        @Inject(Injector) private injector: Injector
    ) {
        super(activeModal, dmLogger, Components.ECIFIOConsumed);
    }

    public ngOnInit(): void {
        this.modalContent = {
            title: "ECIF IO Details",
            subTitle: `${this.wbsId} | ${this.wbsName}`,
        };
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.isLoading = false;
        this.sharedFunctionsService.focus(AccessibilityConstants.ClosePopUp, true);
        this.filteredIoList = this.transformToIoDetailsWithProject(this.ecifIoConsumptionDetails);
    }

    public filterIoList(): void {
        this.filteredIoList = this.filteredIoList.filter((i) => i.projectDetails.every((j) => this.isNearExpiry(j.daysBeforeExpiry)));
    }

    public resetFilter(): void {
        this.filteredIoList = this.transformToIoDetailsWithProject(this.ecifIoConsumptionDetails);
    }

    public onFilterChange(): void {
        this.isFilterCheckEnable = !this.isFilterCheckEnable;
        if (this.isFilterCheckEnable) {
            this.filterIoList();
        }
        else {
            this.resetFilter();
        }        
    }

    /**
     * Moves the focus on the screen to the next object with the given ID.
     */
    public moveFocusNext(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && !event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.ClosePopUp);
        }
    }

    /**
     * Moves the focus on the screen to the previous object with the given ID.
     * @param event
     * @param id
     */
    public moveFocusPrev(event: KeyboardEvent, id: string): void {
        if (event.keyCode === 9 && event.shiftKey) {
            this.sharedFunctionsService.moveFocus(event, id, AccessibilityConstants.CloseClinModal);
        }
    }

    /**
     * Open IO End date extension modal
     * @param ioNumber ioNumber
     * @param projectId projectId
     * @param projectName projectName
     */
    public openIoExtensionModal(ioNumber: string, projectId: string): void {
        this.closeModal();
        const modalRef: NgbModalRef = this.modalService.open(EcifIOEndDateExtensionModalComponent, {
            backdrop: "static",
            centered: true,
            windowClass: "dm-modal-v2 clin-modal in active",
            injector: this.injector
        });  
        
        if (this.ecifIoConsumptionDetails) {
            const selectedIo = this.ecifIoConsumptionDetails.filter((w) => w.projectId === projectId)[0].ioDetails.filter((i) => i.ioNumber === ioNumber)[0];
            modalRef.componentInstance.wbsId = selectedIo.wbsId;            
            modalRef.componentInstance.ioNumber = selectedIo.ioNumber;
            modalRef.componentInstance.currentEndDate = selectedIo.endDate;
            modalRef.componentInstance.engagementId = this.isProjectContext ? this.sharedFunctionsService.getEngagementIdFromProjectId(this.wbsId) : this.wbsId;
        }
    }
    
    /**
     * Toggle expansion of project
     * @param projectId projectId
     */
    public toggleEcifIo(entity: IIoDetailsWithProject): void {
        entity.isExpanded = !entity.isExpanded;
    }

    /**
     * Check if day is within range
     * @param days days before expiry
     * @returns {boolean}
     */
    public isEndDateWithinRange(days: string, thresholdDays: number): boolean {
        if (isNaN(parseInt(days, 10))) {
            return false;
        }
        else if (parseInt(days, 10) <= thresholdDays) {
            return true;
        }
        return false;
    }

    /**
     * Get absolute days
     * @param days days
     * @returns absolute days
     */
    public getAbsoluteDays(days: string): string {
        if (isNaN(parseInt(days, 10))) {
            return days;
        }
        else {
            return Math.abs(parseInt(days, 10)).toString();
        }
    }

    /**
     * Check if day is beyond range
     * @param days days before expiry
     * @returns {boolean}
     */
    public isEndDateBeyondRange(days: string, thresholdDays: number): boolean {
        if (isNaN(parseInt(days, 10))) {
            return false;
        }
        else if (parseInt(days, 10) >= thresholdDays) {
            return true;
        }
        return false;
    }

    public isExpired(days: string): boolean {
        return this.isEndDateWithinRange(days, -1);
    }

    public isNearExpiry(days: string): boolean {
        return this.isEndDateWithinRange(days, 30);
    }

    public isSafe(days: string): boolean {
        return this.isEndDateBeyondRange(days, 31);
    }

    public onSearch(val: string): void {
        this.filteredIoList = this.transformToIoDetailsWithProject(this.ecifIoConsumptionDetails);
        if (val) {
            this.filteredIoList = this.filteredIoList.filter((i) => i.ioNumber.toLocaleLowerCase().includes(val.toLocaleLowerCase()) || i.projectDetails.some((j) => j.projectName.toLocaleLowerCase().includes(val.toLocaleLowerCase())) || i.projectDetails.some((j) => j.projectId.toLocaleLowerCase().includes(val.toLocaleLowerCase())));

            const foundByIOList = this.filteredIoList.filter((i) => i.ioNumber.toLocaleLowerCase().includes(val.toLocaleLowerCase()));

            if (foundByIOList && foundByIOList.length) {
                this.filteredIoList = foundByIOList;
            } else {
                const foundByProjectList = this.filteredIoList.filter((i) => i.projectDetails.some((j) => j.projectName.toLocaleLowerCase().includes(val.toLocaleLowerCase())) || i.projectDetails.some((j) => j.projectId.toLocaleLowerCase().includes(val.toLocaleLowerCase())));
                
                foundByProjectList.map((i) => {
                    const foundChildProjects = i.projectDetails.filter((j) => j.projectName.toLocaleLowerCase().includes(val.toLocaleLowerCase()) || j.projectId.toLocaleLowerCase().includes(val.toLocaleLowerCase()));
                    i.projectDetails = foundChildProjects;
                });
                
                this.filteredIoList = foundByProjectList;
            }
        }
    }
    private transformToIoDetailsWithProject(data: IEcifIoConsumption[]): IIoDetailsWithProject[] {
        const result: IIoDetailsWithProject[] = [];
      
        const ioMap: Map<string, IEcifIoConsumptionDetails[]> = new Map();
        
        data.forEach((project) => {
            project.ioDetails.forEach((ioDetail) => {
                const projectDetail = {
                    projectId: project.projectId,
                    projectName: project.projectName,
                    wbsId: ioDetail.wbsId,
                    startDate: ioDetail.startDate,
                    endDate: ioDetail.endDate,
                    daysBeforeExpiry: ioDetail.daysBeforeExpiry,
                    total: ioDetail.total,
                    consumed: ioDetail.consumed,
                    currency: ioDetail.currency,
                };
                if (ioMap.has(ioDetail.ioNumber)) {
                    ioMap.get(ioDetail.ioNumber).push(projectDetail);
                } else {
                    ioMap.set(ioDetail.ioNumber, [projectDetail]);
                }
            });
        });
      
        ioMap.forEach((value, key) => {
            result.push({
                ioNumber: key,
                projectDetails: value,
                isExpanded: true
            });
        });
        return result;
    }   
        
    
}

