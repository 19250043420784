import { ResourceType } from "./services/contracts/changerequest.contract";
import { IBillablityType } from "./services/contracts/changerequestv2.contract";
import { IDmTab } from "./services/contracts/common.contracts";
import { IWbsResponseMessages } from "./services/contracts/wbs-engagement.contracts";

export class APIConstants {
    public static GetCountries = "OneProfile.GetCountries";
    public static GetStatesBasedOnCountry = "OneProfile.GetStatesBasedOnCountry";
    public static GetCitiesBasedOnStateCountry = "OneProfile.GetCitiesBasedOnStateCountry";
    public static BulkUserBasicDetails = "OneProfile.BulkUserBasicDetails";
    public static GetTrainingDetails = "OneProfile.GetTrainingDetails";
    public static SearchCompanyCodes = "OneProfile.SearchCompanyCode";
    public static GetCustomerEngagementDomains = "OneProfile.GetCustomerEngagementDomains";
    public static GetUserDetailsFromBpId = "OneProfile.GetUserDetailsFromOneProfile";
    public static GetSubconOnboardingStatusFromAlias = "OneProfile.GetSubconOnboardingStatusFromAlias";
    public static EngagementDetailsByEngagementId = "PMS.EngagementDetails";
    public static SearchEngagements = "PMS.SearchEngagements";
    public static UploadFiletoBlob = "PMS.UploadFiletoBlob";
    public static CreateInternalEngagement = "PMS.CreateInternalEngagement";
    public static AddRoleToInternalEngagement = "PMS.AddRoleToInternalEngagement";
    public static ValidateCostCenter = "PMS.ValidateCostCenter";
    public static ValidateRelease = "PMS.ValidateRelease";
    public static ValidateCustomerConfidentiality = "MDG.ValidateCustomerConfidentiality";
    public static ActivateEngagementFinancialPlan = "PMS.ActivateEngagement";
    public static ProjectDetailsAPI = "PMS.ProjectDetails";
    public static PoListAPI = "POService.POList";
    public static FinancialRolesAPI = "FinancialRolesService.Roles";
    public static PoSaveGrAPI = "POService.POSave GR";
    public static CopyFinancials = "PMS.CopyFinancials";
    public static AmendmentDetailsByEngagementIdAndCrNumber = "PMS.Amendment Details";
    public static EngagementListForLoggedInUser = "PMS.EngagementList";
    public static EngagementFinancials = "PMS.EngagementFinancials";
    public static WbsEngPatchRequest = "PMS.EngagementDetails Update";
    public static WbsEngStatusPatch = "PMS.EngagementStatus Update";
    public static WbsProjPatchRequest = "PMS.ProjectDetails Update";
    public static WbsServicePatchRequest = "PMS.ServiceDetails Update";
    public static WbsTaskPatchRequest = "PMS.TaskDetails Update";
    public static WbsTaskPostRequest = "PMS.NewTask";
    public static WbsValidateDate = "PMS.ValidateDate";
    public static WbsGetEngagementBillStatusDetails = "PMS.GetEngagementBillStatusDetails";
    public static WbsGetEngagementETMAndRunFFSeqDetails = "PMS.GetEngagementETMAndRunFFSeqDetails";
    public static GetClinSlin = "PMS.GetClinSlin";
    public static GetPlanForecastParams = "PMS.GetPlanForecastParams";
    public static DelegatedByDetailsAPI = "Delegation.DelegationDetails DelegatedBy";
    public static DelegatedToDetailsAPI = "Delegation.DelegationDetails DelegatedTo";
    public static NotificationsAPI = "PMS.NotificationEmails";
    public static GetSkillGracePeriodAPI = "GRM.GetSkillGracePeriodForResourceSkills";
    public static InvoiceAPI = "SalesOrder.InvoiceData";
    public static InvoiceGetPdf = "SalesOrder.GetInvoicePDF";
    public static GetInternalEngagementFinancialsList = "GetInternalEngagementFinancialsList";
    public static GetProjectAssignmentsForUserAPI = "GRM.GetProjectAssignmentsForUser";
    public static GetResourcesAPI = "GRM.GetResourceDetails";
    public static UpdateResourceDetailsAPI = "GRM.UpdateResourceDetails";
    public static ResourceDomainDataAPI = "GRM.ResourceDomainData";
    public static ResourcePendingActionDetailsAPI = "GRM.ResourcePendingActionDetails";
    public static AddResourceToGRMProjectAPI = "GRM.AddResourceExistingProject";
    public static ReconcileResourceRequests = "GRM.ReconcileResourceRequests";
    public static GetBulkMaintenanceStatus = "GRM.GetBulkMaintenanceStatus";
    public static SearchResourceRequests = "GRM.SearchResourceRequests";
    public static GetBillingMilestoneApi = "Get Billing MileStone";
    public static GetChangeRequestFinancialApi = "Get Change Request Financial";
    public static GetChangeRequestDataApi = "Get Change Request Data";
    public static VirtuosoLinkAPI = "Virtuoso.Get Virtuoso Link Id";
    public static GetVirtuosoApprovalStatus = "Virtuoso.ApprovalStatus";
    public static GetVirtuosoApproverRoles = "Virtuoso.ApproverRoles";
    public static GetVirtuosoRisksAndIssues = "Virtuoso.RisksAndIssues";
    public static GetFaultsApi = "FaultService.GetFaults";
    public static ReprocessFaultApi = "FaultService.ReprocessFault";
    public static MSAuthorizeApi = "ProjectService.Function.MSAuthorize";
    public static OrchestrateDateChange = "ProjectServiceFunction.OrchestrateDateChange";
    public static OrchestrateReleaseandActivate = "ProjectServiceFunction.OrchestrateReleaseandActivate";
    public static OrchestrateRiskReserveChange = "ProjectServiceFunction.OrchestrateRiskReserveChange";
    public static ConcurGetPendingApproval = "ConcurService.GetPendingReportsApproval";
    public static GetCostRateByResourceBPID = "PMS.CostRateByResourceID";
    public static CaptureChangeRequest = "PMS.CaptureChangeRequest";
    public static GetCRSummaryScreenPlanDetails = "PMS.GetCRSummaryScreenPlanDetails";
    public static GetApprovedFinancials = "PMS.GetApprovedFinancials";
    public static CreateFinancialChangeRequest = "PMS.CreateFinancialChangeRequest";
    public static UpdateFinancialChangeRequestStatus = "PMS.UpdateFinancialChangeRequestStatus";
    public static UploadFcrFileAttachment = "PMS.UploadFcrFileAttachment";
    public static GetFinancialChangeRequestApprovers = "PMS.GetFinancialChangeRequestApprovers";
    public static GetFinancialChangeRequestApproverRoles = "PMS.GetFinancialChangeRequestApproverRoles";
    public static GetPendingChangeRequests = "PMS.GetPendingChangeRequests";
    public static GetCampusUrlBasedOnEngagementId = "Campusservice.GetCampusUrlBasedOnEngagementId";
    public static GetRoleDetailsByWbsId = "PMS.GetRoleDetailsByWbsId";
    public static GetCRList = "PMS.GetNonContractualAmendmentList";
    public static GetAmendmentsList = "PMS.GetAmendmentsList";
    public static GetActuals = "PMS.GetActuals";
    public static UpdateActuals = "PMS.UpdateActuals";
    public static GetNpcActuals = "PMS.GetNpcActuals";
    public static UpdateNpcActuals = "PMS.UpdateNpcActuals";
    public static GetCostRateByTaskId = "PMS.GetCostRateByTaskId";
    public static GetUserPreferenceLinks = "PMS.GetUserPreferenceLinks";
    public static AddUserPreferenceLinks = "PMS.AddUserPreferenceLinks";
    public static GetWbsSettings = "PMS.GetWbsSettings";
    public static GetActiveWbsIncidents = "PMS.GetActiveWbsIncidents";
    public static SaveWbsSettings = "PMS.SaveWbsSettings";
    public static CapAmount = "PMS.CAP Amount API";
    public static GetDemandByWbsID = "Get the Demand details based on WbsId";
    public static GetWbsStructuresByWbsId = "Get the Wbs Structures based on WbsId";
    public static UploadExcelData = "PMS.UploadExcelData";
    public static BulkUploadInternalEngagementTemplateLinks = "PMS.GetBulkUploadInternalEngagementTemplates";
    public static GetUploadedInternalEngagement = "PMS.GetUploadedInternalEngagement";
    public static GetAllBulkIntEngUploads = "PMS.GetAllBulkIntEngUploads";
    public static GetAllNotificationSubscriptions = "PMS.GetAllNotificationSubscriptions";
    public static CreateNotificationSubscriptionEntry = "PMS.CreateNotificationSubscriptionEntry";
    public static GetAllBulkIntEngUploadStatus = "PMS.GetAllBulkIntEngUploadStatus";
    public static BulkCreateStatusChange = "PMS.BulkCreateStatusChange";
    public static GetInternalEngNBUEApprovalDocLinks = "PMS.GetInternalEngNBUEApprovalDocLinks";
    public static LaborManagementApprovalsApi = "Time2.0.LaborApprovals";
    public static GetBulkLaborDetails = "Time2.0.BulkLaborDetails";
    public static MicrosoftGraphApi = "MicrosoftGraph.SecurityGroupCheck";
    public static AddProjectToExistingInternalEngagement = "PMS.AddProjectToExistingInternalEngagement";
    public static InternalEngagementPostApprovalDetails = "PMS.InternalEngagementPostApprovalDetails";
    public static CreateCosmicTicketForNotification = "ProjectServiceFunction.CreateCosmicTicketForNotification";
    public static CheckUserMembership = "ProjectServiceFunction.CheckUserMembership";
    public static CheckUserHierarchy = "ProjectServiceFunction.CheckUserHierarchy";
    public static SaveFinancialPlanUpdate = "ProjectServiceFunction.SaveFinancialPlanStatus";
    public static GetUserPreferences = "PMS.GetUserPreferences";
    public static GetReleaseDetails = "PMS.GetReleaseDetails";
    public static SaveUserPreferences = "PMS.SavesUserPreferences";
    public static CreateProjectInGRM = "ProjectServiceFunction.CreateProjectInGRM";
    public static GetContactsByWbsId = "PMS.GetContactsByWbsId";
    public static UpdateCSATContact = "PMS.UpdateCSATContact";
    public static UpdateContactLanguage = "PMS.UpdateContactLanguage";
    public static GetFCRAttachmentLink = "PMS.GetFCRAttachmentLink";
    public static GetAuditHistory = "PMS.AuditHistory";
    public static GetAuditHistoryByWbsIdList = "PMS.GetAuditHistoryByWbsIdList";
    public static PostAuditItem = "PMS.PostAuditItem";
    public static GetEngagementForecastRecommendations = "PsInsights.EngagementForecastRecommendation";
    public static GetProjectForecastRecommendations = "PsInsights.ProjectForecastRecommendation";
    public static GetApprovedRiskReserveDetails = "PMS.GetApprovedRiskReserveDetails";
    public static UpdateTaskRiskReserveDetails = "PMS.UpdateTaskRiskReserveDetails";
    public static ValidateTaskRiskReserveDetails = "PMS.ValidateTaskRiskReserveDetails";
    public static ValuateAfterRiskReserve = "PMS.ValuateAfterRiskReserve";
    public static UploadRRFileToBlob = "PMS.UploadRRFileToBlob";
    public static unitRolesAPI = "Pheonix.UnitRoles";
    public static GetResourceTypeDetails = "PMS.GetResourceTypeDetails";
    public static GetForecast = "PMS.GetForecast";
    public static UpdateForecast = "PMS.UpdateForecast";
    public static PutMisalignedAmendmentUpdates = "PMS.PutMisalignedAmendmentUpdates";
    public static GetMisalignedAmendments = "PMS.GetMisalignedAmendments";
    public static GetActiveMisalignedAmendments = "PMS.GetActiveMisalignedAmendments";
    public static PostValidateMisalignment = "PMS.PostValidateMisalignment";
    public static PostCommitMisalignment = "PMS.PostCommitMisalignment";
    public static GetForecastWithProjectDetails = "GRM.GetForecastWithProjectDetails";
    public static GetEcifIoConsumptionDetails = "PMS.GetEcifIoConsumptionDetails";
    public static GetForecastFlightingDetails = "PMS.GetForecastFlightingDetails";
    public static GetPlanFlightingDetails = "PMS.GetPlanFlightingDetails";
    public static GetActualExpenseDetails = "PMS.GetExpenseDetails";
    public static UpdateProjectClosureStatus = "PMS.UpdateProjectClosureStatus";
    public static InPogressRequestCheck = "ProjectServiceFunction.ProjectClosureInPogressRequestCheck";
    public static GetPJCETMDetails = "PMS.GetPJCETMDetails";
    public static GetPJCMasterData = "PMS.GetPJCMasterData";
    public static GetPendingUnitApprovals = "PMS.GetPendingUnitApprovals";
    public static SendEACUserActionDetails = "PMS.SendEACUserActionDetails";
    public static SubmitEACToPlanRequest = "PMS.SubmitEACToPlanRequest";
    public static GetSyncEACData = "PMS.GetSyncEACData";
    public static GetEACDemandDetails = "PMS.GetEACDemandDetails";
    public static GetRatableProjectDetails = "PMS.GetRatableProjectDetails";
    public static GetForecastFlightingMigrationStatus = "PMS.GetForecastFlightingMigrationStatus";
    public static GetServiceProductFulfilmentDates = "PMS.GetServiceProductFulfilmentDates";
    public static UpdateServiceProductFulfillmentDates = "PMS.UpdateServiceProductFulfillmentDates";
    public static GetFeatureFlag = "FxpFlightingService.GetFeatureFlag";
}

export enum InlineSurveyDataList {
    PortfolioV2Survey = "portfolioSurveyData",
    FvrSurvey = "fvrSurveyData",
    PlanAndForecastSurvey = "planForecastSurveyData",
    ManageEBSV2Survey = "manageEBSv2SurveyData",
    SummaryV2Survey = "summaryv2SurveyData"
}

export class UserPreferenceConstants {
    public static MyPortfolioProjects = "MyPortfolioProjects";
}

/**
 * Fxp partnerTelemetryName for OCV Feedback.
 */
export const FxpPjmTelemetryName: string = "ProjectManagement";

/**
 * Frequently used texts for the tooltips around the application.
 */
export namespace TooltipText {
    export const EBSState: string = "State of the Engagement Breakdown Structure. These states include: Created, Released, Delivery Complete, Ready to Close, Technically Complete, and Closed.";
}

/**
 * Text constants to display when there is no data for the specific entity.
 */
export namespace NoDataText {
    export const NoFinancialDataLoadedText: string = "Could not load financial details.";
    export const NoFinancialAvailableText: string = "No Financials available for this Engagement/Project";
    export const NoEBSDataText: string = "Could not load EBS details.";
    export const NoPurchaseOrders: string = "No Purchase Orders found.";
    export const NoProjects: string = "You do not have any Projects.";
    export const NoServices: string = "You do not have any Services.";
    export const NoEngagements: string = "You do not have any Engagements or Projects.";
    export const NoInternalEngagements: string = "You do not have any Internal Engagements.";
    export const NoAssociatedCustomerEngagements: string = "You do not have any Associated Customer Delivery Engagements.";
    export const NoInvoices: string = "There are no invoices to display.";
    export const NoFinancialChangeRequests: string = "No Financial Change Requests";
    export const NoUnits: string = "There are no units planned for this ";
    export const NoActualsForUnitsEngagement: string = "Please Release and Activate the Engagement to view Unit Details";
    export const NoNpcActials: string = "There are no non-procured materials for this ";
    export const NoNpcActualsForUnreleasedEngagement: string = "Please Release and Activate the Engagement to view non-procured materials";
    export const NoFixedMilestones: string = "There are no fixed fee milestones for this ";
    export const NoContacts: string = "There are no contacts available ";
    export const NoEngagementsOrProjects: string = "There are no engagements or projects for the searched id ";
    export const NoDetailsFound: string = "No details found ";
    export const NoPendingLabor: string = "There are no pending labor approvals. ";
    export const NoFinancialPlan: string = "You do not have any Financial Plan Data ";
    export const NoSnapshots: string = "You currently have no Snapshots";
    export const NoAmendments: string = "You currently have no amendments";
    export const NoPurchaseOrdersCreated: string = "No Purchase Orders created.";
    export const ViewStaffingDetails: string = "Project should be released and Current Financial Plan should be active to view Staffing details.";
    export const NoRecentlyViewedEntityMessage: string = "There are no recently viewed engagements or projects";
    export const NoKeyActionText: string = "You have no actions pending for the day.";
    export const NoAssociatedEngagements: string = "Unable to get Associated Engagements Details";
    export const ProjectMarkedForDeletion: string = "Project is marked for deletion.";
}

/**
 * Text constants for error messages from APIs or other.
 */
export namespace ErrorText {
    export const virtuosoLinkIdErrorText: string = "Could not get the Virtuoso ID";
    export const riskReserveAmountErrorText: string = "Could not get Risk Reserve Approved Amount";
}

/**
 * Validation messages for the Goodsreceipt/Manage suppliers info
 */
export namespace GoodsReceiptValidationMessages {
    export const FFSInvalidDataRange: string = "Value should be in the range of 0-100%.";
    export const MATInvalidDataRange: string = "Value should be a positive integer value.";
    export const DeliveredAmtGreaterThanOrderedAmt: string = "Enter a number less than or equal to the ordered amount for this line item.";
    export const DeliveredAmtLessThanInvoicedAmt: string = "Delivered amount should be greater than the total of parked (1) and invoiced (2) for line item.";
    export const DeliveredAmtIsNotAValidNumber: string = "Enter a valid number with maximum length not more than three.";
}

/**
 * Response messages to display on the screen (via FXP banners) regarding WBS updates the user has tried to make.
 */
export namespace WBSResponseMessages {
    export const Engagement: IWbsResponseMessages = {
        OnSaveSuccess: "# has been updated",
        OnSavePartialSuccess: "Warning: # has been partially updated",
        OnSaveFailure: "Error on saving # data Please try again."
    };
    export const Project: IWbsResponseMessages = {
        OnSaveSuccess: "Success: # details have been updated",
        OnSavePartialSuccess: "Warning: # has been partially updated",
        OnSaveFailure: "Error on saving # data! Please try again."
    };
    export const Service: IWbsResponseMessages = {
        OnSaveSuccess: "Success: # details have been updated",
        OnSavePartialSuccess: "Warning: # has been partially updated",
        OnSaveFailure: "Error on saving # data Please try again."
    };
    export const Task: IWbsResponseMessages = {
        OnSaveSuccess: "Success: # details have been updated",
        OnSavePartialSuccess: "Warning: # has been partially updated",
        OnSaveFailure: "Error on saving # data! Please try again."
    };
    export const TaskAdded: IWbsResponseMessages = {
        OnSaveSuccess: "# added. Complete setup in Financials view",
        OnSavePartialSuccess: undefined,
        OnSaveFailure: "Error adding new #! Please try again."
    };
}

/**
 * Component failure messages displayed on the page if a generic error occurs. Tells the user to log a SNOW ticket.
 */
export namespace ComponentFailureMessages {
    const openTicketText: string = " Please try to refresh the page. If the problem still persists, please click the ? icon and click the link to Create a Ticket for Project Management issues.";
    export const InternalEngagementCostBreakdownComponent: string = "Could not load Engagement Breakdown Structure." + openTicketText;
    export const CostConsumptionComponent: string = "Could not load Cost Consumption." + openTicketText;
    export const CustomerEngagementComponent: string = "Could not load associated Customer Engagements." + openTicketText;
    export const LaborConsumptionComponent: string = "Could not load Labor Consumption." + openTicketText;
    export const MyPortfolioGridData: string = "Could not fetch Engagements." + openTicketText;
    export const MyPortfolioInternalEngagementOverviewComponent: string = "Could not fetch Internal Engagements." + openTicketText;
    export const MyPursuitsComponent: string = "Could not load My Pursuits." + openTicketText;
    export const MyPursuitsInlineNavigationComponent: string = "Could not fetch Internal Engagements." + openTicketText;
    export const InLineNavigationComponent: string = "Could not fetch Engagement." + openTicketText;
    export const ProjectSummaryComponent: string = "Could not load Project Summary." + openTicketText;
    export const ProjectFinancialComponent: string = "Could not load Project Financial." + openTicketText;
    export const ProjectStaffingComponent: string = "Could not load Project Staffing." + openTicketText;
    export const ProjectInvoiceComponent: string = "Could not load Project Purchase Orders." + openTicketText;
    export const ProjectActualsComponent: string = "Could not load Actuals for the Project." + openTicketText;
    export const ProjectNpcActualsComponent: string = "Could not load NPC Actuals for the Project." + openTicketText;
    export const ProjectMilestoneComponent: string = "Could not load Project Billing Milestones." + openTicketText;
    export const ProjectPOUpdate: string = "Could not update Project Purchase Orders." + openTicketText;
    export const ProjectInvoiceDataComponent: string = "Could not load Invoices for the Project." + openTicketText;
    export const InternalEngagementSummaryComponent: string = "Could not load Internal Engagement Summary." + openTicketText;
    export const InternalEngagementFinancialComponent: string = "Could not load Internal Engagement Financial." + openTicketText;
    export const InternalAssociatedEngagementsComponent: string = "Could not load associated Internal Engagements." + openTicketText;
    export const EngagementSummaryComponent: string = "Could not load Engagement Summary." + openTicketText;
    export const EngagementFinancialComponent: string = "Could not load Engagement Financial." + openTicketText;
    export const EngagementStaffingComponent: string = "Could not load Engagement Staffing." + openTicketText;
    export const EngagementInvoiceComponent: string = "Could not load Engagement Purchase Orders." + openTicketText;
    export const EngagementMilestoneComponent: string = "Could not load Engagement Billing Milestones." + openTicketText;
    export const EngagementInvoiceDataComponent: string = "Could not load Invoices for the Engagement." + openTicketText;
    export const EngagementActualsComponent: string = "Could not load Actuals for the Engagement." + openTicketText;
    export const EngagementNpcActualsComponent: string = "Could not load NPC Actuals for the Project." + openTicketText;
    export const EngagementAmendmentComponent: string = "Could not load Amendments for the Engagement." + openTicketText;
    export const EngagementPOUpdate: string = "Could not update Engagement Purchase Orders." + openTicketText;
    export const LandingPlannedHours: string = "Could not load planned hours." + openTicketText;
    export const PurchaseOrdersComponent: string = "Could not load Purchase Orders." + openTicketText;
    export const ContactsComponent: string = "Could not load Contacts." + openTicketText;
    export const InternalAssociatedEngagementsV2Component: string = "Could not load associated Internal Engagements V2." + openTicketText;
}

/**
 * Pages within the app that can be the source of action.
 * These constants are used for Telemetry.
 */
export namespace SourceConstants {
    /**
     * Source Component Names
     */
    export namespace Component {
        export const CreateInternalEngagementPage: string = "CreateInternalEngagement";
        export const EngagementDetailsPage: string = "Engagement";
        export const EngagementSummaryPage: string = "EngagementSummary"; // retire by end of 2021
        export const InternalEngagementSummaryPage: string = "InternalEngagementSummary";
        export const FinancialPage: string = "Financials";
        export const ManageSuppliersPage: string = "ManageSuppliers";
        export const ManageWBSPage: string = "ManageEBS";
        export const MilestonesPage: string = "Milestones";
        export const NavigationDropdown: string = "DropdownNavigation";
        export const PortfolioPage: string = "Portfolio";
        export const ProjectSummaryPage: string = "ProjectSummary";
        export const RecentlyViewed: string = "RecentlyViewed";
        export const SearchPage: string = "AdvancedSearch";
        export const StaffingPage: string = "Staffing";
        export const ToolTipComponent: string = "ToolTip";
        export const ActualsPage: string = "Actuals";
        export const RequestStateChangeModal: string = "RequestStateChangeModal";
        export const ContactsPage: string = "Contacts";
        export const CreateContactModal: string = "CreateContactModal";
        export const TypeAheadComponent: string = "TypeAhead";
        export const PlanForecastComponent: string = "PlanForecast";
        export const TeamStructureModal: string = "TeamStructureModal";
        export const KeyActions: string = "KeyActions";
        export const ManageEBSPage: string = "ManageEBS";
        export const EngagementSummaryV2Page: string = "EngagementSummaryV2";
        export const ProjectSummaryV2Page: string = "ProjectSummaryV2";
        export const FinancialChangeRequest: string = "FinancialChangeRequest";
        export const NavBarNotification: string = "NavBarNotification";
        export const NewInternalEngagementV2: string = "NewInternalEngagementV2";
        export const RenameSnapshotModal: string = "RenameSnapshotModal";
        export const NavigationComponent: string = "NavigationComponent";
        export const PlanForecastV2Component: string = "PlanForecastV2";
        export const PlanForecastDetailsComponent: string = "PlanForecastDetails";
        export const FcrExpensesFormControlComponent: string = "FcrExpensesFormControlComponent";
        export const DecoComponent: string = "DecoPreValidationComponent";
        export const SyncEACToPlanSubmitComponent: string = "SyncEACToPlanSubmitComponent";
        export const SyncEACToPlanNotificationComponent: string = "SyncEACToPlanNotificationComponent";
    }

    /**
     * Source Method Names
     */
    export namespace Method {
        export const ClearText: string = "ClearText";
        export const LogSearchClick: string = "LogSearchClick";
        export const RemoveFromSelectedUsers: string = "RemoveFromSelectedUsers";
        export const LogSuppliersClick: string = "LogSuppliersClick";
        export const LogPONumberClick: string = "LogPONumberClick";
        export const LoadProjectLinks: string = "LoadProjectLinks";
        export const EditKeyLinksModal: string = "EditKeyLinksModal";
        export const AddKeyLinksModal: string = "AddKeyLinksModal";
        export const LogKeyActionsClickEvent: string = "LogKeyActionsClickEvent";
        export const OpenFeedbackModal: string = "OpenFeedbackModal";
        export const CreateProject: string = "CreateProject";
        export const CreateInternalEngagement: string = "CreateInternalEngagement";
        export const ToggleDropDown: string = "ToggleDropDown";
        export const NavigateToSearch: string = "NavigateToSearch";
        export const UiRouterStateChanged: string = "UiRouterStateChanged";
        export const NavigateToHomePage: string = "NavigateToHomePage";
        export const OpenWhatsNewModal: string = "OpenWhatsNewModal";
        export const RefreshEntity: string = "RefreshEntity";
        export const RefreshMyPortfolio: string = "RefreshMyPortfolio";
        export const GlobalSearch: string = "GlobalSearch";
        export const NavigateToAdvancedSearch: string = "NavigateToAdvancedSearch";
        export const SearchHistoryUsageLogger: string = "SearchHistoryUsageLogger";
        export const ChangeSnapshotName: string = "ChangeSnapshotName";
        export const OpenUnitTransactionsModal: string = "OpenUnitTransactionsModal";
        export const PinEntity: string = "PinEntity";
        export const UnpinEntity: string = "UnpinEntity";
        export const LogSearchEventForDmLogger: string = "LogSearchEventForDmLogger";
        export const LogEmployeeClick: string = "LogEmployeeClick";
        export const LogCompassEvent: string = "LogCompassEvent";
        export const LogFinancialsClick: string = "LogFinancialsClick";
        export const LogProjectClick: string = "LogProjectClick";
        export const OnToggle: string = "OnToggle";
        export const OpenAssociatedInternalEngagementsModal: string = "OpenAssociatedInternalEngagementsModal";
        export const SubmitFcr: string = "SubmitFcr";
        export const SetTab: string = "SetTab";
        export const OpenEditRiskReserveModal: string = "OpenEditRiskReserveModal";
        export const OnBotIconClicked: string = "OnBotIconClicked";
        export const OpenContractBaseline: string = "OpenContractBaseline";
        export const OpenDeliveryBaseline: string = "OpenDeliveryBaseline";
        export const OpenCurrentFinancialPlan: string = "OpenCurrentFinancialPlan";
        export const LogLearnMoreClick: string = "LogLearnMoreClick";
        export const CopyFinancialPlan: string = "CopyFinancialPlan";
        export const OnFilterChange: string = "OnFilterChange";
        export const OpenFcrForm: string = "OpenFcrForm";
        export const LogLearnMoreNBUEClick: string = "LogLearnMoreNBUEClick";
        export const LogEngagementClick: string = "LogEngagementClick";
        export const LogPJMClick: string = "LogPJMClick";
        export const OpenAddTaskModal: string = "OpenAddTaskModal";
        export const ToggleUploadDropdown: string = "ToggleUploadDropdown";
        export const ShowBulkUpload: string = "ShowBulkUpload";
        export const LogFileUpload: string = "LogFileUpload";
        export const UploadTemplateClick: string = "UploadTemplateClick";
        export const LearnMoreIntEngLinkClick: string = "LearnMoreIntEngLinkClick";
        export const LogNewInternalEngagementClick: string = "LogNewInternalEngagementClick";
        export const OnToggleExpandCollapse: string = "OnToggleExpandCollapse";
        export const CurrentPageChangedHandler: string = "CurrentPageChangedHandler";
        export const ItemPerPageChangedHandler: string = "ItemPerPageChangedHandler";
        export const SortData: string = "SortData";
        export const LogMoreOptionsClick: string = "LogMoreOptionsClick";
        export const LogIntEngagementItemClick: string = "LogIntEngagementItemClick";
        export const LoadFinancialOverview: string = "LoadFinancialOverview";
        export const OpenEditModal: string = "OpenEditModal";
        export const OpenTeamStructureModal: string = "OpenTeamStructureModal";
        export const LogInvoiceClick: string = "LogInvoiceClick";
        export const SaveUserPreferenceLinks: string = "SaveUserPreferenceLinks";
        export const LogEvent: string = "LogEvent";
        export const OpenUserPreferenceLinksModal: string = "OpenUserPreferenceLinksModal";
        export const OpenReturnedResourceModal: string = "OpenReturnedResourceModal";
        export const LogClick: string = "LogClick";
        export const InvoiceSort: string = "InvoiceSort";
        export const LogDocListItemClick: string = "LogDocListItemClick";
        export const LogMoreInfoClick: string = "LogMoreInfoClick";
        export const AddNewTask: string = "AddNewTask";
        export const UpdateProjectDetails: string = "UpdateProjectDetails";
        export const CreateLogAndSendNotification: string = "CreateLogAndSendNotification";
        export const UpdateTeamDetails: string = "UpdateTeamDetails";
        export const CreateLogAndSendNotificationForProjectTeamChange: string = "CreateLogAndSendNotificationForProjectTeamChange";
        export const ToggleResources: string = "ToggleResources";
        export const OnStatusChange: string = "OnStatusChange";
        export const UpdateEbsState: string = "UpdateEbsState";
        export const TooltipClicked: string = "TooltipClicked";
        export const UpdateEngagementDetails: string = "UpdateEngagementDetails";
        export const UpdateServiceDetails: string = "UpdateServiceDetails";
        export const UpdateTaskDetails: string = "UpdateTaskDetails";
        export const SearchEngagementOrProject: string = "SearchEngagementOrProject";
        export const GetDropDownItems: string = "GetDropDownItems";
        export const OpenLoginIssuesModal: string = "OpenLoginIssuesModal";
        export const LaunchLumiraLoginHelper: string = "LaunchLumiraLoginHelper";
        export const TrackSignoutLinkClicked: string = "TrackSignoutLinkClicked";
        export const OnPostMessageListener: string = "OnPostMessageListener";
        export const LogExpandCollapseClick: string = "LogExpandCollapseClick";
        export const LogEngagementSelect: string = "LogEngagementSelect";
        export const ExpandCollapseEngagements: string = "ExpandCollapseEngagements";
        export const OnSelectedFilterChanged: string = "OnSelectedFilterChanged";
        export const ConfirmContinue: string = "ConfirmContinue";
        export const ConfirmMilestoneDatesSave: string = "ConfirmMilestoneDatesSave";
        export const OpenConfirmationModal: string = "OpenConfirmationModal";
        export const OnEbsStateChangeProject: string = "OnEbsStateChangeProject";
        export const OnEbsStateChange: string = "OnEbsStateChange";
        export const SaveButtonClick: string = "SaveButtonClick";
        export const SaveProjectDetails: string = "SaveProjectDetails";
        export const OpenStatusChangeModal: string = "OpenStatusChangeModal";
        export const OpenEditEngagementDetailsModal: string = "OpenEditEngagementDetailsModal";
        export const OpenEditProjectModal: string = "OpenEditProjectModal";
        export const CreateTeamStructure: string = "CreateTeamStructure";
        export const EditTeamStructure: string = "EditTeamStructure";
        export const LinkClicked: string = "LinkClicked";
        export const OpenManageEBSV2FeedbackModal: string = "OpenManageEBSV2FeedbackModal";
        export const LogLearnMore: string = "LogLearnMore";
        export const OpenInitiateNewCSATContactRequestModal: string = "OpenInitiateNewCSATContactRequestModal";
        export const UpdateCSATContactModal: string = "UpdateCSATContactModal";
        export const UpdateContactLanguageModal: string = "UpdateContactLanguageModal";
        export const Submit: string = "Submit";
        export const OnReasonCodeChange: string = "OnReasonCodeChange";
        export const LogSplitFurther: string = "LogSplitFurther";
        export const DeleteRow: string = "DeleteRow";
        export const OnChangeRole: string = "OnChangeRole";
        export const OnChangeRoleForNewRoles: string = "OnChangeRoleForNewRoles";
        export const CreateAssignmentForExistingProject: string = "CreateAssignmentForExistingProject";
        export const SaveDraftandRedirect: string = "SaveDraftandRedirect";
        export const SearchTasks: string = "SearchTasks";
        export const LoadSuspendPopup: string = "LoadSuspendPopup";
        export const LoadUnsuspendPopup: string = "LoadUnsuspendPopup";
        export const LoadRquestRoleMaintenancePopup: string = "LoadRquestRoleMaintenancePopup";
        export const LoadCancelPopup: string = "LoadCancelPopup";
        export const RemoveRole: string = "RemoveRole";
        export const TruncatePopup: string = "TruncatePopup";
        export const CopyRequest: string = "CopyRequest";
        export const SplitPlannedPopup: string = "SplitPlannedPopup";
        export const OnReasonChange: string = "OnReasonChange";
        export const OnDemandChange: string = "OnDemandChange";
        export const LoadCopyResourcePopup: string = "LoadCopyResourcePopup";
        export const SubmitRemoveRole: string = "SubmitRemoveRole";
        export const ExpandCollapseAllDemands: string = "ExpandCollapseAllDemands";
        export const LogCreateClick: string = "LogCreateClick";
        export const LogEditRoleClick: string = "LogEditRoleClick";
        export const LogDropdownClick: string = "LogDropdownClick";
        export const GridExpandIcon: string = "GridExpandIcon";
        export const FilteredProjectIdChanged: string = "FilteredProjectIdChanged";
        export const FilteredServiceIdChanged: string = "FilteredServiceIdChanged";
        export const FilteredTaskIdChanged: string = "FilteredTaskIdChanged";
        export const LogGrmClick: string = "LogGrmClick";
        export const LogSubconClick: string = "LogSubconClick";
        export const OnResourceAcceptDecline: string = "OnResourceAcceptDecline";
        export const LoadResourceRejectionPopup: string = "LoadResourceRejectionPopup";
        export const ApproveResource: string = "ApproveResource";
        export const LogRecentlyViewedClicks: string = "LogRecentlyViewedClicks";
        export const ExpandCollapseAllProjects: string = "ExpandCollapseAllProjects";
        export const ExpandCollapseProjects: string = "ExpandCollapseProjects";
        export const ExpandCollapseServices: string = "ExpandCollapseServices";
        export const AddTaskModal: string = "AddTaskModal";
        export const OpenEditProjectTeam: string = "OpenEditProjectTeam";
        export const OpenEditServiceModal: string = "OpenEditServiceModal";
        export const OpenEditTaskModal: string = "OpenEditTaskModal";
        export const GetCustomerEngagementDetails: string = "GetCustomerEngagementDetails";
        export const StartComponentLoadTelemetryTimer: string = "StartComponentLoadTelemetryTimer";
        export const EndComponentLoadTelemetryTimer: string = "EndComponentLoadTelemetryTimer";
        export const StartBusinessTaskTelemetryTimer: string = "StartBusinessTaskTelemetryTimer";
        export const EndBusinessTaskTelemetryTimer: string = "EndBusinessTaskTelemetryTimer";
        export const SetComponentAsUsable: string = "SetComponentAsUsable";
        export const LogEventInFxp: string = "LogEventInFxp";
        export const LogAPIEvent: string = "LogAPIEvent";
        export const GetCRDetailsList: string = "GetCRDetailsList";
        export const Get: string = "Get";
        export const GetEngagementFullDetails: string = "GetEngagementFullDetails";
        export const GetWbsFullDetailsV2: string = "GetWbsFullDetailsV2";
        export const GetEntityFinancialDetails: string = "GetEntityFinancialDetails";
        export const SendExcelDataToService: string = "SendExcelDataToService";
        export const LogGrmCreateInternalProjectFault: string = "LogGrmCreateInternalProjectFault";
        export const LogGrmAddRoleFault: string = "LogGrmAddRoleFault";
        export const GetFinancialsDataForMyPortfolioEngagementList: string = "GetFinancialsDataForMyPortfolioEngagementList";
        export const GetEngagementFinancialArrayV2: string = "GetEngagementFinancialArrayV2";
        export const GetPurchaseOrderDetailsByWbsId: string = "GetPurchaseOrderDetailsByWbsId";
        export const GetProjectFullDetails: string = "GetProjectFullDetails";
        export const GetEngagementListForLoggedInUserV2: string = "GetEngagementListForLoggedInUserV2";
        export const GetClinSlinForEngagementId: string = "GetClinSlinForEngagementId";
        export const GetClinSlinForProjectId: string = "GetClinSlinForProjectId";
        export const GetPinnedEntitiesFromUserPreferences: string = "GetPinnedEntitiesFromUserPreferences";
        export const GetAllActiveDelegatedByListV2: string = "GetAllActiveDelegatedByListV2";
        export const GetAllActiveDelegatedToListV2: string = "GetAllActiveDelegatedToListV2";
        export const GetGRMRequestsProjectId: string = "GetGRMRequestsProjectId";
        export const GetGRMRequestsProjectIdV2: string = "GetGRMRequestsProjectIdV2";
        export const GetGRMRequestsBasedOnProjectId: string = "GetGRMRequestsBasedOnProjectId";
        export const OnSaveChangesClick: string = "OnSaveChangesClick";
        export const NgOnInit: string = "NgOnInit";
        export const PopulateSearchResults: string = "PopulateSearchResults";
        export const LoadCRDetails: string = "LoadCRDetails";
        export const CancelCr: string = "CancelCr";
        export const GetVirtuosoApproverData: string = "GetVirtuosoApproverData";
        export const GetVirtuosoApproverDataV2: string = "GetVirtuosoApproverDataV2";
        export const GetVirtuosoLink: string = "GetVirtuosoLink";
        export const GetVirtuosoRisksAndIssues: string = "GetVirtuosoRisksAndIssues";
        export const GetChangeRequestApprovalStatus: string = "GetChangeRequestApprovalStatus";
        export const GetChangeRequestApprovalStatusV2: string = "GetChangeRequestApprovalStatusV2";
        export const RetrieveCostRateData: string = "RetrieveCostRateData";
        export const SubmitForActivation: string = "SubmitForActivation";
        export const ContinueToRelease: string = "ContinueToRelease";
        export const UpdateEngagementIdForSubmissionIndicator: string = "UpdateEngagementIdForSubmissionIndicator";
        export const UploadFileAttachment: string = "UploadFileAttachment";
        export const ActivateFinancialPlan: string = "ActivateFinancialPlan";
        export const CheckIfEngagementIsSubmittedForActivation: string = "CheckIfEngagementIsSubmittedForActivation";
        export const GetUnapprovedLabor: string = "GetUnapprovedLabor";
        export const PopulateData: string = "PopulateData";
        export const GetUnapprovedExpenseFromConcur: string = "GetUnapprovedExpenseFromConcur";
        export const LoadClinSlin: string = "LoadClinSlin";
        export const GetProjectsPendingLabor: string = "GetProjectsPendingLabor";
        export const UpdateSnapshotViewUrls: string = "UpdateSnapshotViewUrls";
        export const UploadBulkIntEngExcel: string = "UploadBulkIntEngExcel";
        export const GetInvoicePDF: string = "GetInvoicePDF";
        export const UpdateProjectTeamStructure: string = "UpdateProjectTeamStructure";
        export const CallUpdateEbsStateApi: string = "CallUpdateEbsStateApi";
        export const CallUpdateApi: string = "CallUpdateApi";
        export const OnCountryChange: string = "OnCountryChange";
        export const InitializeForm: string = "InitializeForm";
        export const VerifyIsUserPartOfSSOSSTGroup: string = "VerifyIsUserPartOfSSOSSTGroup";
        export const VerifyIsUserPartOfInternalEngagementNBUEGroup: string = "VerifyIsUserPartOfInternalEngagementNBUEGroup";
        export const ValidateProjectCostCenter: string = "ValidateProjectCostCenter";
        export const SaveRoleDetails: string = "SaveRoleDetails";
        export const AddToRoleDetailsObject: string = "AddToRoleDetailsObject";
        export const GetDemandResourceRequestForRole: string = "GetDemandResourceRequestForRole";
        export const GetSkillsandGracePeriod: string = "GetSkillsandGracePeriod";
        export const GetFinancialRoles: string = "GetFinancialRoles";
        export const GetPacificDateTime: string = "GetPacificDateTime";
        export const BindResourceImageToArray: string = "BindResourceImageToArray";
        export const GetResourceCommitedHours: string = "GetResourceCommitedHours";
        export const GetClinSlinData: string = "GetClinSlinData";
        export const SubmitForRoleMaintainenceAction: string = "SubmitForRoleMaintainenceAction";
        export const GetLaborDeliveryDate: string = "GetLaborDeliveryDate";
        export const UpdateCSATContact: string = "UpdateCSATContact";
        export const UpdateContactLanguage: string = "UpdateContactLanguage";
        export const DeleteCSATContact: string = "DeleteCSATContact";
        export const OnSubmitFeedback: string = "OnSubmitFeedback";
        export const GetPendingLaborApprovalCountBasedOnAssignments: string = "GetPendingLaborApprovalCountBasedOnAssignments";
        export const GetPendingExpenseReports: string = "GetPendingExpenseReports";
        export const GetPendingApprovalCountByDateForApprover: string = "GetPendingApprovalCountByDateForApprover";
        export const GetPendingResources: string = "GetPendingResources";
        export const SetVirtuosoLink: string = "SetVirtuosoLink";
        export const SetCampusUrl: string = "SetCampusUrl";
        export const LoadUserPreferenceLinks: string = "LoadUserPreferenceLinks";
        export const GetUserList: string = "GetUserList";
        export const GetStorageItems: string = "GetStorageItems";
        export const GetCityList: string = "GetCityList";
        export const GetCompanyCodeList: string = "GetCompanyCodeList";
        export const GetCustomerList: string = "GetCustomerList";
        export const PostData: string = "PostData";
        export const GetData: string = "GetData";
        export const GetBlobData: string = "GetBlobData";
        export const PatchData: string = "PatchData";
        export const PutData: string = "PutData";
        export const DeleteData: string = "DeleteData";
        export const ExportData: string = "ExportData";
        export const GetMisalignedAmendmentsViewModel: string = "GetMisalignedAmendmentsViewModel";
        export const ChangeAssignedDemandForAmendment: string = "ChangeAssignedDemandForAmendment";
        export const GetExistingDemandList: string = "GetExistingDemandList";
        export const AssignAmendmentToExistingDemand: string = "AssignAmendmentToExistingDemand";
        export const SetCreatedBy: string = "SetCreatedBy";
        export const AddUpdatedMisalignedAmendmentToProjectsToBeSaved: string = "AddUpdatedMisalignedAmendmentToProjectsToBeSaved";
        export const SubmitEngagementLevelUpdates: string = "SubmitEngagementLevelUpdates";
        export const SaveMisalignedAmendmentChanges: string = "SaveMisalignedAmendmentChanges";
        export const GetUpdatedMisalignedAmendment: string = "GetUpdatedMisalignedAmendment";
        export const RecalculateCostRate: string = "RecalculateCostRate";
        export const CloseModal: string = "CloseModal";
        export const ToggleSection: string = "ToggleSection";
        export const AddProjectClicked: string = "AddProjectClicked";
        export const GoBackToPreviousScreen: string = "GoBackToPreviousScreen";
        export const OnDeleteProject: string = "OnDeleteProject";
        export const ToggleProject: string = "ToggleProject";
        export const OpenAddRolesModal: string = "OpenAddRolesModal";
        export const DeleteRole: string = "DeleteRole";
        export const SaveRequest: string = "SaveRequest";
        export const DiscardChanges: string = "DiscardChanges";
        export const AddNewRole: string = "AddNewRole";
        export const AdjustExisting: string = "AdjustExisting";
        export const OnEditClick: string = "OnEditClick";
        export const AddNewExpense: string = "AddNewExpense";
        export const OpenSummaryV2FeedbackModal: string = "OpenSummaryV2FeedbackModal";
        export const SendCosmicCancellation: string = "SendCosmicCancellation";
        export const ProjectClosureInProgressRequestCheck: string = "ProjectClosureInPogressRequestCheck";
        export const ProjectClosureIPendingLaborExpenseCheck: string = "ProjectClosureIPendingLaborExpenseCheck";
        export const GetActiveWbsIncidents: string = "GetActiveWbsIncidents";
        export const GetPJCMasterData: string = "Get PJCMaster Data";
        export const LogSyncEACToPlanOperation: string = "LogSyncEACToPlanOperation";
        export const HandleSyncEACToPlanSubmit: string = "HandleSyncEACToPlanSubmit";
    }
}

export namespace LogEventConstants {
    export const APICalled: string = "API Called";
    export const APISuccess: string = "API Succeeded";
    export const APIFailure: string = "API Failed";
    export const AssociatedCustomerEngagements: string = "Associated Customer Engagements Obtained";
    export const AssociatedInternalEngagements: string = "Associated Internal Engagements List Item Clicked";
    export const ChangedValues: string = "Values Changed";
    export const ClearTextClick: string = "Clear Typeahead Text Icon Clicked";
    export const ContactPJCIcon: string = "Contact PJC Link Clicked";
    export const CreateCustomLink: string = "Create Custom Link Clicked";
    export const CustomLinkSubmit: string = "Custom Link Submitted Successfully";
    export const CreateInternalEngagement: string = "Internal Engagement Created";
    export const EngagementCreatedSuccessfully: string = "Engagement Successfully Created"; // todo consider putting this elsewhere
    export const FinancialsLinkClicked: string = "Go to Financials Link Clicked";
    export const EngagementsProjectStandardLinkClicked: string = "Key Project Link Clicked";
    export const EngagementListCollapsed: string = "Engagements List Collapsed";
    export const EngagementListExpanded: string = "Engagements List Expanded";
    export const ProjectListCollapsed: string = "Projects List Collapsed";
    export const ProjectListExpanded: string = "Projects List Expanded";
    export const SpecificEngagementMoreOptionsClick: string = "More Options for Individual Item on the Engagements List Clicked";
    export const SpecificEngagementExpand: string = "Individual Engagement Item on the Engagements List Expanded";
    export const SpecificEngagementCollapse: string = "Individual Engagement Item on the Engagements List Collapsed";
    export const SpecificProjectExpand: string = "Individual Project Expanded";
    export const SpecificProjectCollapse: string = "Individual Project Collapsed";
    export const SpecificServiceExpand: string = "Individual Service Expanded";
    export const SpecificServiceCollapse: string = "Individual Service Collapsed";
    export const EngagementNavigation: string = "Engagement Clicked";
    export const FinancialsPlanTab: string = "Financial Plan Tab Opened";
    export const FinancialsPlanTabName: string = "Financial Plan Tab Name";
    export const FinancialsTakeSnapshot: string = "Took a New Financial Snapshot";
    export const FeedbackClick: string = "Feedback Modal Opened";
    export const FinChangeRequestIDClick: string = "Approval Change Request ID Clicked";
    export const FilterByStatusClick: string = "Status Filter Changed";
    export const GRMApiFailed: string = "GRMApi Failed"; // todo consider putting this elsewhere, it goes w the success
    export const DropdownCreated: string = "Dropdown List Created";
    export const DropdownNumberOfDelegationProjectsListed: string = "Number of Delegation Projects Listed";
    export const DropdownNumberOfEngagementsListed: string = "Number of Engagements Listed";
    export const DropdownNumberOfProjectsListed: string = "Number of Projects Listed";
    export const DropdownTotalNumberOfEntitiesListed: string = "Total Number of Entities Listed";
    export const InternalEngagementBulkUploadOpen: string = "Bulk Upload/Edit Option for Internal Engagements Clicked";
    export const InvoiceNavigationClick: string = "Go to Invoices Link Clicked";
    export const InvoiceDocumentNumberClick: string = "Invoice Number Clicked";
    export const ProjectNavigation: string = "Project Clicked";
    export const InvoiceMoreInfoClick: string = "Invoice More Details Tooltip Clicked";
    export const NewIntEngagementClick: string = "New Internal Engagement Link Clicked";
    export const ManageSuppliersShowOrHideGoodReceiptsBasedOnAction: string = "Goods Receipt Shown/Hidden Based on Action Taken";
    export const ManageSuppliersPurchaseOrderLinkClicked: string = "Navigated to Purchase Order via Link Click";
    export const ManageSuppliersSaveGoodsReceiptSuccess: string = "Goods Receipt Successfully Saved"; // todo unsure if this is useful since we also log the save button click
    export const ManageSupppliersSaveGoodsReceiptButtonClicked: string = "Save Goods Receipt Button Clicked";
    export const NewFinChangeRequestClick: string = "New Financial Change Request Link Clicked";
    export const FinancialChangeRequestCreated: string = "Financial Change Request Created";
    export const LinkClick: string = " Link Clicked";
    export const LearnMoreIntEngLink: string = "Learn More Link for New Internal Engagements Clicked";
    export const LearnMoreFinancialsClick: string = "Learn More Link for Financial and Actuals Clicked";
    export const LearnMoreNBUEClick: string = "Learn More Link for Request NBUE Hours Clicked";
    export const LearnMoreTecoClick: string = "Learn More Link for TECO State Change Clicked";
    export const LearnMoreManageEBS: string = "Learn More Link for Manage EBS Clicked";
    export const EBSRequestStateChange: string = "EBS State Change Requested";
    export const ManageWBSEngagementEdit: string = "WBS Engagement Edited";  /* Engagement was saved successfully (received API response) */
    export const ManageWBSEngagementEditLink: string = "Edit WBS Engagement Link Clicked"; /* Link to open the edit engagement modal clicked. */
    export const ManageWBSEngagementEditSaveClick: string = "Edit WBS Engagement Save Button Clicked"; /* Save button was clicked on edit engagement modal. No info on fail/success */
    export const ManageEBSEngagementEditLink: string = "Edit EBS Engagement Link Clicked"; /* Link to open the edit engagement modal clicked. */
    export const ManageEBSEngagementEdit: string = "EBS Engagement Edited";  /* Engagement was saved successfully (received API response) */

    export const ManageWBSProjectEditSaveClick: string = "Edit WBS Project Save Button Clicked"; /* Save button was clicked on edit project modal. No info on fail/success */
    export const ManageWBSProjectEdit: string = "WBS Project Edited"; /* Project was saved successfully (received API response) */
    export const ManageWBSProjectEditLink: string = "Edit WBS Project Link Clicked"; /* Link to open the edit project modal clicked. */
    export const ManageEBSServiceEdit: string = "WBS Service Edited"; /* Service was saved successfully (received API response) */
    export const ManageEBSServiceEditLink: string = "Edit WBS Service Link Clicked"; /* Link to open the edit service modal clicked. */
    export const ManageEBSServiceEditSaveClick: string = "Edit WBS Service Save Button Clicked"; /* Save button was clicked on edit service modal. No info on fail/success */
    export const ManageEBSTaskEditLink: string = "Edit WBS Task Link Clicked"; /* Link to open the edit add modal clicked. */
    export const ManageEBSProjectEditLink: string = "Edit EBS Project Link Clicked"; /* Link to open the edit project modal clicked. */

    export const ManageWBSTaskAddLink: string = "Add WBS Task Link Clicked"; /* Link to open the add task modal clicked. */
    export const ManageWBSTaskAddSaveClick: string = "Add WBS Task Save Button Clicked"; /* Save button was clicked on add task modal. No info on fail/success */
    export const EBSAddTask: string = "New EBS Task Added"; /* Task was added successfully (received API response) */
    export const ManageEBSTaskAddLink: string = "Add EBS Task Link Clicked"; /* Link to open the add task modal clicked. */


    export const MilestonesConfirmBilling: string = "Billing Block Added/Confirmed";
    export const MilestonesUnconfirmBilling: string = "Billing Block Removed/Unconfirmed";
    export const MilestonesDateConfirmation: string = "Milestone dates confirmed";
    export const ModalClosed: string = "Modal Closed";
    export const PageLoadStarted: string = "Page Load Started";
    export const PageLoadCompleted: string = "Page Load Completed";
    export const PageUsable: string = "Page Usable";
    export const BusinessTaskStarted: string = "Business Task Started";
    export const BusinessTaskCompleted: string = "Business Task Completed";
    export const PinEngagement: string = "Engagement Pinned";
    export const PinProject: string = "Project Pinned";
    export const PortfolioCustomerEngagementsToggleView: string = "Engagements List on Portfolio Page Toggled";
    export const PortfolioPaginationPageChanged: string = "Engagement List Page changed using Pagination Arrows";
    export const ProjectFinancialsLink: string = "Project Financials Link Clicked";
    export const ProjectsSummary: string = "Projects Summary";
    export const RemoveMultiSelectUser: string = "Multiselect User in Typeahead Removed";
    export const SAPApiFailed: string = "SAP API Failed";
    export const SearchAdvancedSearch: string = "Advanced Search Used";
    export const SelectAdvancedSearch: string = "Advanced Search Option Selected";
    export const SearchCustomerNameValue: string = "Customer Name Searched";
    export const SearchEngagementIdValue: string = "Engagement Id Searched";
    export const SearchEngagementNameValue: string = "Engagement Name Searched";
    export const SearchOpportunityIdValue: string = "Opportunity Id Searched";
    export const SearchParameters: string = "Search Parameters";
    export const SearchText: string = "Search Text";
    export const SearchHistoryResultUsed: string = "Search History Dropdown Element Selected";
    export const SearchPPJMorPJMName: string = "Primary Domain or Project Manager / T&E Approver Name Searched";
    export const SearchProjectIdValue: string = "Project Id Searched";
    export const SearchProjectNameValue: string = "Project Name Searched";
    export const SearchQuickSearch: string = "Quick Search Selected";
    export const SearchGlobalNavigationDropdown: string = "Global Navigation Dropdown Search Used";
    export const SearchTypeAheadClick: string = "Search Option in Type Ahead Component Clicked";
    export const SortEngagementsList: string = "Engagement List Sort Option Used";
    export const SortInvoiceList: string = "Invoice List Sort Option Used";
    export const StaffingAcceptResourceRequest: string = "Staffing Resource Request Accepted";
    export const StaffingDeclineResourceRequest: string = "Staffing Resource Request Rejected";
    export const StaffingEditResourceRequest: string = "Staffing Resource Request Edited";
    export const StaffingResourceAcceptedOrDeclined: string = "Staffing Resource Accepted or Declined";
    export const StaffingResourceRejectionModalOpened: string = "Staffing Resource Rejection Modal Opened";
    export const StaffingResourceRejectionReasonSelected: string = "Staffing Resource Rejection Reason Selected";
    export const StaffingViewResourceManagement: string = "View Resource Management Link Clicked";
    export const StaffingProjectsCollapsed: string = "Project Collapsed";
    export const StaffingProjectsExpanded: string = "Project Expanded";
    export const ToolTipClick: string = "ToolTip Clicked";
    export const ContractBaseLineClick: string = "View Contract Baseline Link Clicked";
    export const DeliveryBaseLineClick: string = "View Delivery Baseline Link Clicked";
    export const CurrentFinancialPlanClick: string = "View Current Financial Plan Link Clicked";
    export const UnpinEngagement: string = "Engagement Unpinned";
    export const UnpinProject: string = "Project Unpinned";
    export const UploadFileClick: string = "Upload File Link for Internal Engagements Clicked";
    export const UploadTemplateClick: string = "Bulk Upload Template Link for Internal Engagements Clicked";
    export const UpdateCSATContactModal: string = "Update CSAT Contact clicked";
    export const UpdateContactLanguageModal: string = "Update Contact Language clicked";
    export const CreateCSATContactModal: string = "Create CSAT Contact clicked";
    export const SubmittedCreateCSATContactForm: string = "Create CSAT Contact Form Submitted";
    export const EmployeeLinkClick: string = "Employee Name Clicked";
    export const CompassLinkClick: string = "CompassOne Deal Link Clicked";
    export const PurchaseOrderLink: string = "Purchase Order Link Clicked";
    export const ManagerSuppliersClick: string = "Manage Suppliers Link Clicked";
    export const RequestSAPAccessIcon: string = "Request SAP Access Link Clicked";
    export const ExpiredSAPAccessIcon: string = "Expired SAP User Access Link Clicked";
    export const StaffingResourceIdSelected: string = "Resource ID Radio Button Selected";
    export const SearchStaffing: string = "Search Demands/Resources Clicked";
    export const SpiltRoleLink: string = "Split Role Link Clicked";
    export const SpiltRoleCommandBar: string = "Spilt Role Command Bar Link Clicked";
    export const RemoveRoleCommandBar: string = "Remove Role Command Bar Clicked";
    export const RemoveRoleButtonClicked: string = "Remove Roel Button Clicked";
    export const CopyResourceRequestCommandBar: string = "Copy Resource Request Command Bar Clicked";
    export const CopyResourceRequestButton: string = "Copy Resource Request Button Clicked";

    export const SuspendRoleCommandBar: string = "Suspend Role Command Bar Clicked";
    export const SuspendRoleButton: string = "Suspend Role Button Clicked";
    export const SuspendReasonSelected: string = "Suspend Role Reason Selected";
    export const UnsuspendRoleCommandBar: string = "Unsuspend Role Command Bar Clicked";
    export const UnsuspendRoleButton: string = "Unsuspend Role Button Clicked";
    export const SuspendRequestSuccess: string = "Suspend Request Succeeded";
    export const SubmitSuspendRequest: string = "Suspend Request Submit Button Clicked";
    export const CancelRequestSuccess: string = "Cancel Role Request Succeeded";
    export const SubmitCancelRequest: string = "Cancel Role Request Submit Button Clicked";
    export const RequestRoleMaintenanceSuccess: string = "Request for Role Maintenance Succeeded";
    export const SubmitRequestRoleMaintenance: string = "Request for Role Maintenance Submit Button Clicked";
    export const UnsuspendRoleRequestSuccess: string = "Unsuspend Role Request Succeeded";
    export const SubmitUnsuspendRoleRequest: string = "Unsuspend Role Request Submit Button Clicked";
    export const UnsuspendRoleReasonSelected: string = "Unsuspend Role Reason Selected";
    export const CopyResourceRequestSuccess: string = "Copy Resource Request Succeeded";
    export const SubmitCopyResourceRequest: string = "Copy Resource Request Submit Button Clicked";
    export const CopyResourceEditDetailsOnGRMLink: string = "Edit Copy Resource Details on Resource Management Link Clicked";
    export const RequestMaintenanceCommandBar: string = "Request Role Maintenance Command Bar Clicked";
    export const RequestMaintenanceButton: string = "Request Role Maintenance Button Clicked";
    export const CancelRoleCommandBar: string = "Cancel Role Command Bar Clicked";
    export const CancelRoleButton: string = "Cancel Role Button Clicked";
    export const TruncateRoleCommandBar: string = "Truncate Role Command Bar Clicked";
    export const TruncateRoleLink: string = "Truncate Role Link Clicked";
    export const TruncateRequestSuccess: string = "Truncate Role Succeeded";
    export const SubmitTruncate: string = "Truncate Submit Button Clicked";
    export const LearnMoreStaffing: string = "Learn More about staffing Maintenance Actions Clicked";
    export const ExpandCollapseDemands: string = "Expand/Collapse Role/Resource Clicked";
    export const SplitPlanRoleSelected: string = "Split Plan Role Selected";
    export const StaffingRequestSplitSuccess: string = "Request to Split Plan Succeeded";
    export const StaffingSplitFurtherClicked: string = "Split Role Further Button Clicked";
    export const SplitRoleDeleted: string = "Split Plan Role Deleted";
    export const ProjectFilterStaffing: string = "Project ID Filter Selected";
    export const ServiceFilterStaffing: string = "Service ID Filter Selected";
    export const TaskFilterStaffing: string = "Task ID Filter Selected";
    export const CreateRequestStaffing: string = "Create Request Link Clicked";
    export const EditRoleStaffing: string = "Edit Role Clicked";
    export const RoleActionsDropdown: string = "Role Actions Dropdown Clicked";
    export const RequestStateChangeTooltip: string = "Request EBS State Change Tooltip Clicked";
    export const CancelTecoRequestTooltip: string = "Cancel TECO Request Tooltip Clicked";
    export const EbsStateInfoTooltip: string = "EBS State Info Tooltip Clicked";
    export const ExtendedPjmInfoTooltip: string = "Extended PJM Info Tooltip Clicked";
    export const EbsStateSelected: string = "EBS State Requested in State Change Dropdown";
    export const EBSEditToggleResourceExpand: string = "EBS Edit Project/Service/Task Toggle Resources Expanded Expanded";
    export const EBSEditToggleResourceCollapse: string = "EBS Edit Project/Service/Task Toggle Resources Expanded Collapsed";
    export const OpenMilestonesConfirmationModal: string = "Confirm or Unconfirm Milestone Modal Opened";
    export const MilestoneConfirmed: string = "Milestone Confirmed";
    export const MilestoneUnconfirmed: string = "Milestone Unconfirmed";
    export const ManageEBSCreateTeamStructureLinkClicked: string = "EBS Create Teams Team Link Link Clicked";
    export const ManageEBSEngagementEditLinkClicked: string = "Edit EBS Engagement Link Clicked";
    export const ManageEBSEditTeamStructureLinkClicked: string = "EBS Edit Team Structure Link Clicked";
    export const UnitTransactionDetailsClicked: string = "Unit Transaction Details Clicked";
    export const ManageEBSEditTeamStructureSubmitClicked: string = "EBS Edit Engagement Team Structure Submit Clicked";
    export const ManageEBSEditProjectTeamStructureLinkClicked: string = "EBS Edit Project Team Structure Link Clicked";
    export const ManageEBSViewTeamStructureLinkClicked: string = "EBS View Team Structure Link Clicked";
    export const ManageEBSEngagementRequestStatusChangeLinkClicked: string = "EBS Engagement Request Status Change Link Clicked";
    export const ManageEBSProjectRequestStatusChangeLinkClicked: string = "EBS Project Request Status Change Link Clicked";
    export const ManageEBSServiceRequestStatusChangeLinkClicked: string = "EBS Service Request Status Change Link Clicked";
    export const ManageEBSTaskRequestStatusChangeLinkClicked: string = "EBS Task Request Status Change Link Clicked";
    export const ManageEBSEditProjectTeamStructureSubmitClicked: string = "EBS Edit Project Team Structure Submit Button Clicked";
    export const ManageEBSEngagementEditSubmitClicked: string = "EBS Edit Engagement Submit Button Clicked";
    export const ManageEBSEditProjectDetailsSubmitClicked: string = "EBS Edit Project Details Submit Button Clicked";
    export const ManageEBSProjectDetailsEdit: string = "EBS Project Details Edited";
    export const ManageEBSProjectTeamStructureEdit: string = "EBS Project Team Structure Edited";
    export const ManageEBSTaskAddSaveClick: string = "Add EBS Task Save Button Clicked";
    export const ManageEBSAddTask: string = "New EBS Task Added";
    export const ManageEBSServiceEditSubmitClick: string = "EBS Edit Service Submit Button Clicked";
    export const ManageEBSTaskEditSubmitClick: string = "EBS Edit Task Submit Button Clicked";
    export const ManageEBSTaskEdit: string = "EBS Task Edited";
    export const ManageEBSEngagementTeamStructureEdit: string = "EBS Engagement Team Structure Edited";
    export const ManageEBSEngagementTeamStructureCascaded: string = "EBS Engagement Team Structure Cascaded";
    export const ConfidentialTooltip: string = "Confidential Info Tooltip Clicked";
    export const PubsecTooltip: string = "Pubsec Info Tooltip Clicked";
    export const ContractTypeInfoTooltip: string = "Contract Type Info Tooltip Clicked";
    export const ChatIconClicked: string = "Chat Icon Clicked";
    export const MailIconClicked: string = "Mail Icon Clicked";
    export const ManageEBSFeedbackClick: string = "EBS Feedback Modal Opened";
    export const ManageEBSProjectActionsDropdownClick: string = "EBS Project Actions Dropdown Clicked";
    export const ManageEBSServiceActionsDropdownClick: string = "EBS Service Actions Dropdown Clicked";
    export const ManageEBSTaskActionsDropdownClick: string = "EBS Task Actions Dropdown Clicked";
    export const NavigateToEngagementEBSPageClicked: string = "EBS View Engagement EBS Link Clicked";
    export const NavigateToProjectSummaryLinkClicked: string = "EBS Project Name Link Clicked To Navigate to Project Summary";
    export const ManageEBSAddTaskModalClosed: string = "EBS Add Task Modal Closed";
    export const ManageEBSEngagementRequestStatusChangeModalClosed: string = "EBS Engagement Request Status Change Modal Closed";
    export const ManageEBSProjectRequestStatusChangeModalClosed: string = "EBS Project Request Status Change Modal Closed";
    export const ManageEBSServiceRequestStatusChangeModalClosed: string = "EBS Service Request Status Change Modal Closed";
    export const ManageEBSTaskRequestStatusChangeModalClosed: string = "EBS Task Request Status Change Modal Closed";
    export const ManageEBSEditProjectDetailsModalClosed: string = "EBS Edit Project Details Modal Closed";
    export const ManageEBSEditProjectTeamStructureModalClosed: string = "EBS Edit Project Team Structure Modal Closed";
    export const ManageEBSEditServiceModalClosed: string = "EBS Edit Service Modal Closed";
    export const ManageEBSEditTaskModalClosed: string = "EBS Edit Task Modal Closed";
    export const ManageEBSEditEngagementTeamStructureModalClosed: string = "EBS Edit Engagement Team Structure Modal Closed";
    export const ManageEBSViewTeamStructureModalClosed: string = "EBS View Team Structure Modal Closed";
    export const ManageEBSEditEngagementDetailsModalClosed: string = "EBS Edit Engagement Details Modal Closed";
    export const EditRiskReserveDetailsModalClicked: string = "Edit Risk Reserve Modal Clicked";
    export const FinancialsDetailsLinkClicked: string = "Go to Financials Details Link Clicked";
    export const AssociatedInternalEngagementDetailsModalOpened: string = "Associated Internal Engagement Details Modal Opened";
    export const AssociatedInternalEngagementDetailsModalClosed: string = "Associated Internal Engagement Details Modal Closed";
    export const BackToFinancialsLinkClicked: string = "Back to Financials Link Clicked";
    export const SummaryV2FeedbackClick: string = "Summary V2 Feedback Modal Opened";
    export const DecoPreValidation: string = "Deco PreValidation";
    export enum BPE {
        SyncEACToPlanClicked = "SyncEACToPlanSubmitComponent.SyncEACToPlanClicked",
        SubmitSyncEACToPlanRequest = "SyncEACToPlanSubmitComponent.SyncEACToPlanSubmitRequest",
        SyncEACToPlanNotification = "SyncEACToPlanNotificationComponent.SyncEACToPlanNotification",
    }
}

export namespace Services {
    export const AADGraphService: string = "AAD Graph API Service";
    export const AddRolesService: string = "Add Roles Service";
    export const Amendments: string = "Amendements Service";
    export const AuditService: string = "Audit Service";
    export const AuditHelperService: string = "Audit Helper Service";
    export const AuthService: string = "Authorization Service";
    export const BulkUploadInternalEngagementService: string = "Bulk Upload Internal Engagement Service";
    export const CacheService: string = "Cache Service";
    export const CampusService: string = "Campus Service";
    export const CapAmountService: string = "Cap Amount Service";
    export const ChangeRequestDemandService: string = "Change Request Demand Service";
    export const ChangeRequestService: string = "Change Request Service";
    export const ConcurService: string = "Concur Service";
    export const ConfigManagerService: string = "Config Manager Service";
    export const ContactService: string = "Contacts Service";
    export const ContextStorageService: string = "Context Storage Service";
    export const CosmicService: string = "Cosmic Functions Service";
    export const CustomerService: string = "Customer Service";
    export const DataService: string = "Data Service";
    export const DecoratorService: string = "Decorator Service";
    export const DmLoggerService: string = "DM Logger Service";
    export const EngagementDetailService: string = "Engagement Details Service";
    export const ExpenseDetailsService: string = "Expense Details Service";
    export const ExportToExcelService: string = "Export to Excel Service";
    export const FaultControlService: string = "Fault Control Service";
    export const FinancialPlanService: string = "Financial Plan Service";
    export const FinancialService: string = "Financial Service";
    export const ForecastRecommendationService: string = "Forecast Recommendation Service";
    export const GRMService: string = "GRM Service";
    export const GrmForecastService: string = "Grm Forecast Service";
    export const InternalEngagementService: string = "Internal Engagement Service";
    export const InvoiceService: string = "Invoices Service";
    export const LaborManagementService: string = "Labor Management Service";
    export const ManageEBSService: string = "Manage EBS Service";
    export const ManageSupplierService: string = "Manage Suppliers Service";
    export const MilestoneService: string = "Milestones Service";
    export const MSAuthService: string = "MS Authorization Service";
    export const MyPortfolioService: string = "My Portfolio Service";
    export const NavigationService: string = "Navigation Service";
    export const NewInternalEngagementService: string = "New Internal Engagement Service";
    export const NotifcationService: string = "Notification Service";
    export const NotificationService: string = "Notification Service";
    export const PJMSignalRService: string = "PJMSignalR Service";
    export const NPCActualsService: string = "NPC Actuals Service";
    export const OneProfileService: string = "One Profile Service";
    export const ProjectDetailService: string = "Project Detail Service";
    export const ProjectService: string = "Project Service";
    export const ProjectServiceFunction: string = "Project Service Functions";
    export const ProjectServiceV2: string = "Project Service V2";
    export const PurchaseOrderService: string = "Purchase Order Service";
    export const ResourceRoleService: string = "Resource Roles Service";
    export const ResourceStaffingService: string = "Resource Staffing Service";
    export const RoleRemoval: string = "Role Removal Service";
    export const SharedFunctions: string = "Shared Functions Service";
    export const StaffingService: string = "Staffing Service";
    export const StaffingViewModelService: string = "Staffing View Model Service";
    export const StoreDispatchService: string = "Store Dispatch Service";
    export const UserPreferenceLinksManagementService: string = "User Preference Links Management Service";
    export const UserPreferenceService: string = "User Preference Service";
    export const VirtuosoService: string = "Virtuoso Service";
    export const FxpFlightingService: string = "FxpFlightingService";
    export const WBSService: string = "WBS Service";
    export const UnitsService: string = "Units Service";
}

export class NotificationEvent {
    public static EngagementUpdated = "EngagementUpdated";
    public static ProjectUpdated = "ProjectUpdated";
    public static ServiceUpdated = "ServiceUpdated";
    public static TaskUpdated = "TaskUpdated";
    public static ReadyForActivation = "ReadyForActivation";
    public static BaseLineFinalized = "BaseLineFinalized";
    public static EBSStateChangeRequest = "EbsStateChangeRequest";
    public static EBSStateChangeRequestFFTeco = "EbsStateChangeRequestFFTeco";
    public static EBSStateChangeRequestMixedTeco = "EbsStateChangeRequestMixedTeco";
    public static EBSStateChangeRequestTMTeco = "EbsStateChangeRequestTMTeco";
    public static EngagementActivationFailed = "EngagementActivationFailed";
    public static EngagementActivationFailedWithRules = "EngagementActivationFailedWithRules";
    public static AddNewCSATContact = "AddNewCSATContact";
    public static UpdateCSATContactLanguage = "UpdateCSATContactLanguage";
    public static ExtendIoEndDate = "ExtendIoEndDate";
    public static EbsStateChangeRequestDeco = "EbsStateChangeRequestDeco";
    public static EbsStateChangeRequestReco = "EbsStateChangeRequestReco";
    public static EbsStateReversalRequest = "EbsStateReversalRequest";
}

export namespace ResourceRequestStatus {
    export const Proposed: string = "Proposed";
    export const Assigned: string = "Assigned";
    export const Closed: string = "Closed";
    export const Complete: string = "Complete";
}

export enum ResourceName {
    SAP_Engagement = "SAP.Engagement",
    SAP_Project = "SAP.Project",
    SAP_Invoice = "SAP.Invoice",
    SAP_Financials = "SAP.Financials",
    SAP_EBS = "SAP.EBS",
    SAP_Staffing = "SAP.Staffing",
    SAP_PurchaseOrder = "SAP.PurchaseOrder",
    Chronos_Labor = "Chronos.Labor",
    Resource_Manager = "ResourceManager"
}
export enum OperationName {
    view = "view",
    edit = "edit",
    add = "add",
    Default_Action = "DefaultAction"
}

export enum ResourceNameV2 {
    Profile = "Profile",
    Schedule = "Schedule",
    Labor = "Labor",
    EngagementBreakdownStructure = "EngagementBreakdownStructure",
    Engagement = "Engagement",
    ProjectFinancials = "ProjectFinancials",
    Project = "Project",
    PurchaseOrder = "PurchaseOrder",
    ProjectAssignment = "ProjectAssignment",
}

/**
 * Collection of route names used for navigation throughout the app.
 */
export namespace RouteName {
    export const Entry: string = "pjmEntry";  /* Parent route only, do not route here */
    export const Landing: string = "pjmEntry.landing";
    export const Reports: string = "pjmEntry.reports";
    export const Search: string = "pjmEntry.search";
    export const Portfolio: string = "pjmEntry.portfolio";
    export const Unauthorized: string = "pjmEntry.unauthorized";
    export const ProjectContactDetails: string = "pjmEntry.projectDetails.contactDetails";
    export const EngagementContactDetails: string = "pjmEntry.engagementDetails.contactDetails";
    export const EngagementDetails: string = "pjmEntry.engagementDetails";  /* Parent route only, do not route here */
    export const EngagementSummary: string = "pjmEntry.engagementDetails.summary";
    export const EngagementFinancials: string = "pjmEntry.engagementDetails.financials";
    export const EngagementStaffing: string = "pjmEntry.engagementDetails.staffing";
    export const EngagementManageSuppliers: string = "pjmEntry.engagementDetails.manageSuppliers";
    export const EngagementMilestones: string = "pjmEntry.engagementDetails.milestones";
    export const EngagementInvoices: string = "pjmEntry.engagementDetails.invoices";
    export const EngagementActuals: string = "pjmEntry.engagementDetails.units";
    export const EngagementPlanForecast: string = "pjmEntry.engagementDetails.planForecast";
    export const NewEngagementForecast: string = "engagementForecast";
    export const NewEngagementPlanForecast: string = "engagementPlanForecastV2";
    export const EngagementFinancialChangeRequest: string = "pjmEntry.engagementDetails.changeRequest";
    export const EngagementFinancialChangeRequestForm: string = "pjmEntry.engagementDetails.changeRequestForm";
    export const EngagementAmendmentDetails: string = "pjmEntry.engagementDetails.amendmentDetails";
    export const EngagementNonProcuredMaterials: string = "pjmEntry.engagementDetails.nonProcuredMaterials";
    export const ProjectFinancialChangeRequest: string = "pjmEntry.projectDetails.changeRequest";
    export const ProjectDetails: string = "pjmEntry.projectDetails";  /* Parent route only, do not route here */
    export const ProjectSummary: string = "pjmEntry.projectDetails.summary";
    export const ProjectFinancials: string = "pjmEntry.projectDetails.financials";
    export const ProjectStaffing: string = "pjmEntry.projectDetails.staffing";
    export const ProjectManageSuppliers: string = "pjmEntry.projectDetails.manageSuppliers";
    export const ProjectMilestones: string = "pjmEntry.projectDetails.milestones";
    export const ProjectInvoices: string = "pjmEntry.projectDetails.invoices";
    export const ProjectActuals: string = "pjmEntry.projectDetails.units";
    export const ProjectPlanForecast: string = "pjmEntry.projectDetails.planForecast";
    export const NewProjectForecast: string = "projectForecast";
    export const NewProjectPlanForecast: string = "projectPlanForecastV2";
    export const ProjectForecast: string = "pjmEntry.projectDetails.projectForecast";
    export const ProjectPlanForecastV2: string = "pjmEntry.projectDetails.projectPlanForecastV2";
    export const ProjectAmendmentDetails: string = "pjmEntry.projectDetails.amendmentDetails";
    export const ProjectFinancialChangeRequestForm: string = "pjmEntry.projectDetails.changeRequestForm";
    export const ProjectNonProcuredMaterials: string = "pjmEntry.projectDetails.nonProcuredMaterials";
    export const InternalEngagementDetails: string = "pjmEntry.internalEngagementDetails"; /* Parent route only, do not route here */
    export const InternalEngagementSummary: string = "pjmEntry.internalEngagementDetails.summary";
    export const InternalEngagementProjects: string = "pjmEntry.internalEngagementDetails.projects";
    export const InternalEngagementFinancials: string = "pjmEntry.internalEngagementDetails.financials";
    export const InternalEngagementStaffing: string = "pjmEntry.internalEngagementDetails.staffing";
    export const CreateInternalEngagement: string = "pjmEntry.createInternalEngagement";
    export const BulkCreateInternalEngagement: string = "pjmEntry.bulkCreateInternalEngagement";
    export const FinancialPlanApprovals: string = "pjmEntry.financialPlanApprovals";
    export const FinancialPlanApprovalsByCrId: string = "pjmEntry.financialPlanApprovalsByCrId";
    export const CreateInternalEngagementV2: string = "pjmEntry.createInternalEngagementV2";
    export const EngagementManageEBS: string = "pjmEntry.engagementDetails.ebs";
    export const ProjectManageEBS: string = "pjmEntry.projectDetails.ebs";
    export const EngagementSummaryV2: string = "pjmEntry.engagementDetails.summaryv2";
    export const ProjectSummaryV2: string = "pjmEntry.projectDetails.summaryv2";
    export const EngagementWbsDetails: string = "pjmEntry.engagementDetails.wbsdetails";
    export const ProjectWbsDetails: string = "pjmEntry.projectDetails.wbsdetails";
    export const PlanAndForecastV2: string = "pjmEntry.engagementDetails.planAndForecastv2";
    export const EngagementForecast: string = "pjmEntry.engagementDetails.engagementForecast";
    export const EngagementPlanForecastV2: string = "pjmEntry.engagementDetails.engagementPlanForecastV2";
    export const EngagementMisalignedAmendmentDetails: string = "pjmEntry.engagementDetails.misalignedAmendmentDetails";
    export const ProjectMisalignedAmendmentDetails: string = "pjmEntry.projectDetails.misalignedAmendmentDetails";
    export const SyncEACToPlanThresholdExceeded: string = "pjmEntry.SyncEACToPlanThresholdExceeded";
}

export enum SAPApiResponseResourceDetailTypeFilters {
    staffed = "staffed",
    planned = "planned",
    proposed = "proposed"
}

export interface IComponent {
    userFriendlyName: string;
    routeName: string[];
    isParentPage: boolean;
}

export interface IChildComponent {
    component: IComponent;
    isCritical: boolean;
}

export namespace Components {
    export const Actuals: IComponent = {
        userFriendlyName: "Units",
        routeName: [RouteName.EngagementActuals, RouteName.ProjectActuals],
        isParentPage: true
    };
    export const ActivateEngagement: IComponent = {
        userFriendlyName: "Release and Activate Engagement Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const CostSummary: IComponent = {
        userFriendlyName: "Cost Summary",
        routeName: undefined,
        isParentPage: false
    };
    export const FcrDetails: IComponent = {
        userFriendlyName: "FCR details",
        routeName: undefined,
        isParentPage: false
    };
    export const HoursSummary: IComponent = {
        userFriendlyName: "Hours Summary",
        routeName: undefined,
        isParentPage: false
    };
    export const NotificationBar: IComponent = {
        userFriendlyName: "Notifications Bar",
        routeName: undefined,
        isParentPage: false
    };
    export const DateChangeNotification: IComponent = {
        userFriendlyName: "Date Change Notification",
        routeName: undefined,
        isParentPage: false
    };
    export const RiskReserveChangeNotification: IComponent = {
        userFriendlyName: "Risk Reserve Change Notification",
        routeName: undefined,
        isParentPage: false
    };
    export const ReleaseAndActivateNotification: IComponent = {
        userFriendlyName: "Release And Activate Notification",
        routeName: undefined,
        isParentPage: false
    };
    export const BulkCreateNotification: IComponent = {
        userFriendlyName: "Bulk Create Notification",
        routeName: undefined,
        isParentPage: false
    };
    export const ProcessNotification: IComponent = {
        userFriendlyName: "Process Notifications Tab",
        routeName: undefined,
        isParentPage: false
    };
    export const AddRole: IComponent = {
        userFriendlyName: "Add Role Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const AdvancedSearch: IComponent = {
        userFriendlyName: "Advanced Search",
        routeName: [RouteName.Search],
        isParentPage: true
    };
    export const AdvancedSearchFilter: IComponent = {
        userFriendlyName: "Advanced Search Filter Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const BulkCreateInternalEngagementStatusModal: IComponent = {
        userFriendlyName: "Bulk Create Internal Engagement Status Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const BulkCreateInternalEngagement: IComponent = {
        userFriendlyName: "Bulk Create Internal Engagement",
        routeName: [RouteName.BulkCreateInternalEngagement],
        isParentPage: true
    };
    export const CapAmounts: IComponent = {
        userFriendlyName: "View Cap Amounts Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const Cceac: IComponent = {
        userFriendlyName: "Cceac",
        routeName: undefined,
        isParentPage: false
    };
    export const ClinSlin: IComponent = {
        userFriendlyName: "Clin Slin Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ECIFIOConsumed: IComponent = {
        userFriendlyName: "ECIF IO Consumed Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ECIFIOEndDateExtension: IComponent = {
        userFriendlyName: "ECIF IO End Date Extension Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalEngagementBulkUploadModal: IComponent = {
        userFriendlyName: "Internal Engagement Bulk Upload Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const CreateInternalEngagement: IComponent = {
        userFriendlyName: "Create Internal Engagement",
        routeName: [RouteName.CreateInternalEngagement],
        isParentPage: true
    };
    export const AddInternalEngagementProject: IComponent = {
        userFriendlyName: "Add Internal Engagement Project",
        routeName: undefined,
        isParentPage: false
    };
    export const CostBreakdown: IComponent = {
        userFriendlyName: "Cost Breakdown",
        routeName: undefined,
        isParentPage: false
    };
    export const CustomerEngagement: IComponent = {
        userFriendlyName: "Customer Engagement",
        routeName: undefined,
        isParentPage: false
    };
    export const CustomerInvoices: IComponent = {
        userFriendlyName: "Customer Invoices",
        routeName: [RouteName.EngagementInvoices, RouteName.ProjectInvoices],
        isParentPage: true
    };
    export const CustomerInvoicesFilter: IComponent = {
        userFriendlyName: "Customer Invoices Filter",
        routeName: undefined,
        isParentPage: false
    };
    export const CustomerInvoicesTableData: IComponent = {
        userFriendlyName: "Customer Invoices Table Data",
        routeName: undefined,
        isParentPage: false
    };
    export const DropDown: IComponent = {
        userFriendlyName: "Drop Down Navigation",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementFinancials: IComponent = {
        userFriendlyName: "Engagement Financials",
        routeName: [RouteName.EngagementFinancials],
        isParentPage: true
    };
    export const EngagementSummary: IComponent = {
        userFriendlyName: "Engagement Summary",
        routeName: [RouteName.EngagementSummary],
        isParentPage: true
    };
    export const EngagementSummaryDetails: IComponent = {
        userFriendlyName: "Engagement Summary Details",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryFinancials: IComponent = {
        userFriendlyName: "Engagement Summary Financials",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryProjects: IComponent = {
        userFriendlyName: "Engagement Summary Projects",
        routeName: undefined,
        isParentPage: false
    };
    export const AmendmentDetails: IComponent = {
        userFriendlyName: "Amendment Details",
        routeName: [RouteName.EngagementAmendmentDetails, RouteName.ProjectAmendmentDetails],
        isParentPage: true
    };
    export const FinancialAmendments: IComponent = {
        userFriendlyName: "Financial Amendments",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialAmendmentsViewWorkbookModal: IComponent = {
        userFriendlyName: "Financial Amendments View Workbook Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialExpenseDetailsModal: IComponent = {
        userFriendlyName: "Financial Expense Details Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialNavigation: IComponent = {
        userFriendlyName: "Financial Navigation",
        routeName: undefined,
        isParentPage: false
    }; // todo can we give this a better name for logging?
    export const FinancialPlan: IComponent = {
        userFriendlyName: "Financial Plan",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialRoleDetailsModal: IComponent = {
        userFriendlyName: "Financial Role Details Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialsApprovals: IComponent = {
        userFriendlyName: "Financial Approvals",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialChangeRequest: IComponent = {
        userFriendlyName: "Financial Change Request Form",
        routeName: [RouteName.EngagementFinancialChangeRequest, RouteName.ProjectFinancialChangeRequest],
        isParentPage: true
    };
    export const FinancialsCostConsumed: IComponent = {
        userFriendlyName: "Financials Cost Consumed",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialsEstimateAtComplete: IComponent = {
        userFriendlyName: "Financials Estimate at Complete",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialSnapshotDetails: IComponent = {
        userFriendlyName: "Financial SnapshotDetails",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialSnapshots: IComponent = {
        userFriendlyName: "Financial Snapshots",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialSummary: IComponent = {
        userFriendlyName: "Financial Summary",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialReleaseAndActivateSummary: IComponent = {
        userFriendlyName: "Release and Activate Summary",
        routeName: undefined,
        isParentPage: false
    };
    export const FinancialSummaryDetails: IComponent = {
        userFriendlyName: "Financial Summary Details",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalEngagementBreakdownStructure: IComponent = {
        userFriendlyName: "Internal Engagement Breakdown Structure",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalEngagementCostBreakdown: IComponent = {
        userFriendlyName: "Internal Engagement Cost Breakdown",
        routeName: undefined,
        isParentPage: false
    };
    export const IntEngCostConsumption: IComponent = {
        userFriendlyName: "Internal Engagement Cost Consumption",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalEngagementProjects: IComponent = {
        userFriendlyName: "Internal Engagement Projects",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalAssociatedEngagements: IComponent = {
        userFriendlyName: "Internal Associated Engagements",
        routeName: undefined,
        isParentPage: false
    };
    export const InternalEngagementFinancial: IComponent = {
        userFriendlyName: "Internal Engagement Financial",
        routeName: [RouteName.InternalEngagementFinancials],
        isParentPage: true
    };
    export const InternalEngagementSummary: IComponent = {
        userFriendlyName: "Internal Engagement Summary",
        routeName: [RouteName.InternalEngagementSummary],
        isParentPage: true
    };
    export const KeyActions: IComponent = {
        userFriendlyName: "Key Actions",
        routeName: undefined,
        isParentPage: false
    };
    export const KeyActionsV2: IComponent = {
        userFriendlyName: "Key ActionsV2",
        routeName: undefined,
        isParentPage: false
    };
    export const KeyIndicators: IComponent = {
        userFriendlyName: "Key Indicators",
        routeName: undefined,
        isParentPage: false
    };
    export const KeyProjectLinks: IComponent = {
        userFriendlyName: "Key Project Links",
        routeName: undefined,
        isParentPage: false
    };
    export const LaborConsumption: IComponent = {
        userFriendlyName: "Labor Consumption",
        routeName: undefined,
        isParentPage: false
    };
    export const LaborRequest: IComponent = {
        userFriendlyName: "Labor Request Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const Delegations: IComponent = {
        userFriendlyName: "Delegations Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageSuppliers: IComponent = {
        userFriendlyName: "Manage Suppliers",
        routeName: [RouteName.EngagementManageSuppliers, RouteName.ProjectManageSuppliers],
        isParentPage: true
    };
    export const ManageSuppliersFilter: IComponent = {
        userFriendlyName: "Manage Suppliers Filter",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageSuppliersTableData: IComponent = {
        userFriendlyName: "Manage Suppliers Table Data",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSAddTask: IComponent = {
        userFriendlyName: "Manage WBS Add Task",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSEditEngagement: IComponent = {
        userFriendlyName: "Manage WBS Edit Engagement",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSEditProject: IComponent = {
        userFriendlyName: "Manage WBS Edit Project",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSEngagementRequestStateChange: IComponent = {
        userFriendlyName: "Manage WBS Engagement Request State Change",
        routeName: undefined,
        isParentPage: false
    };
    export const WbsResourceRequests: IComponent = {
        userFriendlyName: "Wbs Resource requests",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEBSEngagementRequestStateChange: IComponent = {
        userFriendlyName: "Manage EBS Engagement Request State Change",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSInternalEngagement: IComponent = {
        userFriendlyName: "Manage WBS Internal Engagement",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEBSProject: IComponent = {
        userFriendlyName: "Manage EBS Project",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageWBSProjectRequestStateChange: IComponent = {
        userFriendlyName: "Manage WBS Project Request State Change",
        routeName: undefined,
        isParentPage: false
    };
    export const Milestones: IComponent = {
        userFriendlyName: "Milestones",
        routeName: [RouteName.EngagementMilestones, RouteName.ProjectMilestones],
        isParentPage: true
    };
    export const MilestoneConfirmationModal: IComponent = {
        userFriendlyName: "Confirm Milestone Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const Nav: IComponent = {
        userFriendlyName: "Navigation Bar",
        routeName: undefined,
        isParentPage: false
    };
    export const NonProcuredMaterials: IComponent = {
        userFriendlyName: "Non-Procured Materials",
        routeName: [RouteName.EngagementNonProcuredMaterials, RouteName.ProjectNonProcuredMaterials],
        isParentPage: true
    };
    export const PlanForecast: IComponent = {
        userFriendlyName: "Plan & Forecast",
        routeName: [RouteName.EngagementPlanForecast, RouteName.ProjectPlanForecast],
        isParentPage: true
    };
    export const CreateTeamsGroup: IComponent = {
        userFriendlyName: "Create Teams Group Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ContactDetails: IComponent = {
        userFriendlyName: "Contact Details",
        routeName: [RouteName.EngagementContactDetails, RouteName.ProjectContactDetails],
        isParentPage: false
    };
    export const Portfolio: IComponent = {
        userFriendlyName: "My Portfolio",
        routeName: [RouteName.Portfolio],
        isParentPage: true
    };
    export const PortfolioGridData: IComponent = {
        userFriendlyName: "My Portfolio Grid Data",
        routeName: undefined,
        isParentPage: false
    };
    export const PortfolioGridDataFinancialsTab: IComponent = {
        userFriendlyName: "My Portfolio Grid Data Financials Tab",
        routeName: undefined,
        isParentPage: false
    };
    export const PortfolioGridDataOverviewTab: IComponent = {
        userFriendlyName: "My Portfolio Grid Data Overview Tab",
        routeName: undefined,
        isParentPage: false
    };
    export const PortfolioInternalEngagementGridData: IComponent = {
        userFriendlyName: "My Portfolio Internal Engagement Grid Data",
        routeName: undefined,
        isParentPage: false
    };
    export const PortfolioInternalEngagementFinancialsTab: IComponent = {
        userFriendlyName: "My Portfolio Internal Engagement Grid Data Financials Tab",
        routeName: undefined,
        isParentPage: false
    };
    export const PortfolioInternalEngagementOverviewTab: IComponent = {
        userFriendlyName: "My Portfolio Internal Engagement Grid Data Overview Tab",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectFilter: IComponent = {
        userFriendlyName: "Project Filter",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectFilterModal: IComponent = {
        userFriendlyName: "Project Filter Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectFinancials: IComponent = {
        userFriendlyName: "Project Financials",
        routeName: [RouteName.ProjectFinancials],
        isParentPage: true
    };
    export const ProjectSummary: IComponent = {
        userFriendlyName: "Project Summary",
        routeName: [RouteName.ProjectSummary],
        isParentPage: true
    };
    export const ProjectSummaryDetails: IComponent = {
        userFriendlyName: "Project Summary Details",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectSummaryFinancials: IComponent = {
        userFriendlyName: "Project Summary Financials",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectSummaryServices: IComponent = {
        userFriendlyName: "Project Summary Services",
        routeName: undefined,
        isParentPage: false
    };
    export const PurchaseOrders: IComponent = {
        userFriendlyName: "Purchase Orders",
        routeName: undefined,
        isParentPage: false
    };
    export const ContactsTile: IComponent = {
        userFriendlyName: "Contacts Tile",
        routeName: undefined,
        isParentPage: false
    };
    export const ResourceManagement: IComponent = {
        userFriendlyName: "Resource Management Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const RenameSnapshot: IComponent = {
        userFriendlyName: "Rename Snapshot Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const UpdateCSATContactModal: IComponent = {
        userFriendlyName: "Update CSAT Contact Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const UpdateContactLanguageModal: IComponent = {
        userFriendlyName: "Update Contact Language Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const CreateCSATContactModal: IComponent = {
        userFriendlyName: "Create CSAT Contact Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ResourceRejection: IComponent = {
        userFriendlyName: "Resource Rejection Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ReturnedResourceRequest: IComponent = {
        userFriendlyName: "Returned Resource Request Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const RiskReserve: IComponent = {
        userFriendlyName: "Risk Reserve Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const Staffing: IComponent = {
        userFriendlyName: "Staffing",
        routeName: [RouteName.EngagementStaffing, RouteName.ProjectStaffing],
        isParentPage: true
    };
    export const StaffingCommandBar: IComponent = {
        userFriendlyName: "Staffing Command Bar",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarRequestCancellationModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Request Cancellation Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarRoleMaintenanceModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Role Maintenance Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarSplitPlannedModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Split Planned Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarSuspendModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Suspend Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarTruncateModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Truncate Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingCommandBarUnsuspendModal: IComponent = {
        userFriendlyName: "Staffing Command Bar Unsuspend Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const StaffingRequestGrid: IComponent = {
        userFriendlyName: "Staffing Request Grid",
        routeName: undefined,
        isParentPage: false
    };
    export const TabNav: IComponent = {
        userFriendlyName: "Tab Navigation",
        routeName: undefined,
        isParentPage: false
    };
    export const TakeNewSnapshot: IComponent = {
        userFriendlyName: "Take New Snapshot Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const UnapprovedExpense: IComponent = {
        userFriendlyName: "Unapproved Expense Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const UnapprovedLabor: IComponent = {
        userFriendlyName: "Unapproved Labor Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const RecentlyViewed: IComponent = {
        userFriendlyName: "RecentlyViewed",
        routeName: undefined,
        isParentPage: false
    };
    export const WhatsNew: IComponent = {
        userFriendlyName: "What's New Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const TeamStructure: IComponent = {
        userFriendlyName: "Team Structure Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const UserName: IComponent = {
        userFriendlyName: "User Name",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEBS: IComponent = {
        userFriendlyName: "Manage EBS",
        routeName: [RouteName.EngagementManageEBS, RouteName.ProjectManageEBS],
        isParentPage: true
    };
    export const ManageEbsEngagementDetails: IComponent = {
        userFriendlyName: "Manage EBS Engagement Details",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEngagementTeamStructure: IComponent = {
        userFriendlyName: "Manage EBS Engagement Team Structure",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEngagementBreakdownStructure: IComponent = {
        userFriendlyName: "Manage EBS Engagement Breakdown Structure",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsRequestStateChange: IComponent = {
        userFriendlyName: "Manage EBS Request State Change",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsAddTask: IComponent = {
        userFriendlyName: "Manage EBS Add Task",
        routeName: undefined,
        isParentPage: false
    };
    export const PlanForecastAuditHistory: IComponent = {
        userFriendlyName: "Plan Forecast Audit History",
        routeName: undefined,
        isParentPage: false
    };
    export const PlanForecastExport: IComponent = {
        userFriendlyName: "Plan Forecast Export",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEditEngagementDetails: IComponent = {
        userFriendlyName: "Manage EBS Edit Engagement Details",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEditTeamStructure: IComponent = {
        userFriendlyName: "Manage EBS Edit Team Structure",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEditProjectDetails: IComponent = {
        userFriendlyName: "Manage EBS Edit Project Details",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEditServiceDetails: IComponent = {
        userFriendlyName: "Manage EBS Edit Service Details",
        routeName: undefined,
        isParentPage: false
    };
    export const ManageEbsEditTaskDetails: IComponent = {
        userFriendlyName: "Manage EBS Edit Task Details",
        routeName: undefined,
        isParentPage: false
    };

    export const EngagementTecoPrevalidation: IComponent = {
        userFriendlyName: "Engagement TECO prevalidation",
        routeName: undefined,
        isParentPage: false
    };

    export const EngagementDecoPrevalidation: IComponent = {
        userFriendlyName: "DECO prevalidation",
        routeName: undefined,
        isParentPage: false
    };

    export const EngagementRecoPrevalidation: IComponent = {
        userFriendlyName: "RECO prevalidation",
        routeName: undefined,
        isParentPage: false
    };

    export const UnitTransactionDetails: IComponent = {
        userFriendlyName: "Unit Transaction Details",
        routeName: undefined,
        isParentPage: false
    };
    export const EditRiskReserveModal: IComponent = {
        userFriendlyName: "Edit Risk Reserve Details",
        routeName: undefined,
        isParentPage: false
    };
    export const Modal: IComponent = {
        userFriendlyName: "Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryV2: IComponent = {
        userFriendlyName: "Engagement Summary V2",
        routeName: [RouteName.EngagementSummaryV2],
        isParentPage: true
    };
    export const EntitySummaryDetails: IComponent = {
        userFriendlyName: "Entity Summary Details",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryFinancialsV2: IComponent = {
        userFriendlyName: "Engagement Summary Financials V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryContactsV2: IComponent = {
        userFriendlyName: "Summary Contacts V2",
        routeName: undefined,
        isParentPage: false
    };
    export const CancelOrContinueModal: IComponent = {
        userFriendlyName: "Cancel or Continue Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryProjectsV2: IComponent = {
        userFriendlyName: "Engagement Summary Projects V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryInvoicesV2: IComponent = {
        userFriendlyName: "Summary Invoices V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryPurchaseOrdersV2: IComponent = {
        userFriendlyName: "Summary Purchase Orders V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryKeyLinksV2: IComponent = {
        userFriendlyName: "Summary Key Links V2",
        routeName: undefined,
        isParentPage: false
    };
    export const EngagementSummaryInternalAssociatedEngagementsV2: IComponent = {
        userFriendlyName: "Engagement Summary Internal Associated Engagements V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryFinancialsBreakdownComponent: IComponent = {
        userFriendlyName: "Summary Financials Breakdown",
        routeName: undefined,
        isParentPage: false
    };
    export const AddKeyLinksModal: IComponent = {
        userFriendlyName: "Add Key Links Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const EditKeyLinksModal: IComponent = {
        userFriendlyName: "Edit Key Links Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectSummaryV2: IComponent = {
        userFriendlyName: "Project Summary V2",
        routeName: [RouteName.ProjectSummaryV2],
        isParentPage: true
    };
    export const ProjectSummaryFinancialsV2: IComponent = {
        userFriendlyName: "Project Summary Financials V2",
        routeName: undefined,
        isParentPage: false
    };
    export const ProjectSummaryServicesV2: IComponent = {
        userFriendlyName: "Project Summary Services V2",
        routeName: undefined,
        isParentPage: false
    };
    export const SummaryFinancialsBreakdown: IComponent = {
        userFriendlyName: "Summary Financials Breakdown",
        routeName: undefined,
        isParentPage: false
    };
    export const WbsDetails: IComponent = {
        userFriendlyName: "Wbs Details",
        routeName: [RouteName.EngagementWbsDetails, RouteName.ProjectWbsDetails],
        isParentPage: true
    };
    export const PlanForecastV2: IComponent = {
        userFriendlyName: "Plan and Forecast V2",
        routeName: [RouteName.PlanAndForecastV2],
        isParentPage: true
    };
    export const PlanForecastDetails: IComponent = {
        userFriendlyName: "Plan and Forecast Details",
        routeName: undefined,
        isParentPage: false
    };
    export const MisalignedAmendmentDetails: IComponent = {
        userFriendlyName: "Misaligned Amendment Details",
        routeName: [RouteName.EngagementMisalignedAmendmentDetails, RouteName.ProjectMisalignedAmendmentDetails],
        isParentPage: true
    };
    export const MisalignedAmendmentLineitems: IComponent = {
        userFriendlyName: "Misaligned Amendment Lineitems",
        routeName: undefined,
        isParentPage: false
    };
    export const ChangeExistingDemandForMisalignedAmendmentModal: IComponent = {
        userFriendlyName: "Change Existing Demand for Misaligned Amendment Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const MisalignedAmendmentValidationFailedModal: IComponent = {
        userFriendlyName: "Misaligned Amendment Validation Failed Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const BulkUpdateSchedulesModal: IComponent = {
        userFriendlyName: "Bulk Update Schedule Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const ResourceDetails: IComponent = {
        userFriendlyName: "Resource Details",
        routeName: undefined,
        isParentPage: false
    };
    export const DeleteCsatContactModal: IComponent = {
        userFriendlyName: "Delete CSAT Contact Modal",
        routeName: undefined,
        isParentPage: false
    };
    export const WbsStatusChangeNotificationComponent: IComponent = {
        userFriendlyName: "Wbs Status Change Notification",
        routeName: undefined,
        isParentPage: false
    };
    export const SyncEACToPlanNotificationComponent: IComponent = {
        userFriendlyName: "Sync EAC To Plan Notification Component",
        routeName: undefined,
        isParentPage: true
    };
    export const OpenSyncEACToPlanThresholdDialogComponent: IComponent = {
        userFriendlyName: "Open Sync EAC To Plan Threshold Dialog Component",
        routeName: undefined,
        isParentPage: false
    };
}

export enum BulkUpdateScheduleOptions {
    SpecificHoursPerWeek = "SpecificHoursPerWeek",
    EvenlySpreadAcrossDuration = "EvenlySpreadAcrossDuration"
}

export enum DelegationTypeText {
    DelegationOutward = "Delegated to ",
    DelegationInward = "Delegated by "
}

export enum DelegationTitleText {
    DelegationOutward = "Delegated Outward",
    DelegationInward = "Delegated Inward"
}

// Defining ENUMS based on the new/v2 financial api
export enum FinancialType {
    ContractBaseline = "1",
    DeliveryBaseline = "2",
    CurrentFinancialPlan = "3",
    ACTUALS = "ACT",
    ETC = "ETC",
    EAC = "EAC",
    ActualsCurrent = "ACT_CO"
}

export enum BaseLineType {
    ContractBaseline = "1",
    DeliveryBaseline = "2",
    CurrentFinancialPlan = "3"
}

// Defining Internal Engagement Codes that do not have NBUE hours (SR Out, Unit Based)
export const nonNbueInternalEngagementCreationCodes: string[] = ["95", "97", "98", "82", "83", "Z2"];

export interface ICache {
    KeyName: string;
    Duration: number;
}

export namespace CacheKeys {
    export const ConcurPendingReportsApproval: ICache = {
        KeyName: "Concur.PendingReportsApproval",
        Duration: 60
    };
    export const SkillsGracePeriod: ICache = {
        KeyName: "SkillsGracePeriod",
        Duration: 86400
    };
    export const ReconcileCustomerDeliveryProject: ICache = {
        KeyName: "ReconcileCustomerDeliveryProject",
        Duration: 60
    };
    export const DomainDataReasonCodeActionNotes: ICache = {
        KeyName: "DomainDataReasonCodeActionNotes",
        Duration: 86400
    };
    export const PendingLaborApprovalCountBasedOnAssignments: ICache = {
        KeyName: "PendingLaborApprovalCountBasedOnAssignments",
        Duration: 60
    };
    export const BulkUserProfile: ICache = {
        KeyName: "BulkUserProfile",
        Duration: 86400
    };
    export const Cities: ICache = {
        KeyName: "Cities",
        Duration: 86400
    };
    export const Countries: ICache = {
        KeyName: "Countries",
        Duration: 86400
    };
    export const FunctionalSkills: ICache = {
        KeyName: "FunctionalSkills",
        Duration: 86400
    };
    export const ProductSkills: ICache = {
        KeyName: "ProductSkills",
        Duration: 86400
    };
    export const TechnicalSkills: ICache = {
        KeyName: "TechnicalSkills",
        Duration: 86400
    };
    export const UserProfile: ICache = {
        KeyName: "UserProfile",
        Duration: 86400
    };
    export const CustomerEngagementsDomains: ICache = {
        KeyName: "CustomerEngagementsDomains",
        Duration: 86400
    };
    export const CustomerEngagementFinancials: ICache = {
        KeyName: "CustomerEngagementFinancials",
        Duration: 60
    };
    export const InternalEngagementFinancials: ICache = {
        KeyName: "InternalEngagementFinancials",
        Duration: 60
    };
    export const PlanForecastParamsBasedOnEngagementId: ICache = {
        KeyName: "PlanForecastParamsBasedOnEngagementId",
        Duration: 60
    };
    export const WbsFinancials: ICache = {
        KeyName: "WbsFinancials",
        Duration: 60
    };
    export const FinancialRoles: ICache = {
        KeyName: "FinancialRoles",
        Duration: 86400
    };
    export const UserMembership: ICache = {
        KeyName: "UserMembership",
        Duration: 86400
    };
    export const IsUserPartOfPlanForecastPilotSecurityGroup: ICache = {
        KeyName: "PJM.IsUserPartOfPlanForecastPilotSecurityGroup",
        Duration: 86400
    };
    export const WhatsNewReleaseDetails: ICache = {
        KeyName: "PJM.IsUserPartOfPlanForecastPilotSecurityGroup",
        Duration: 86400
    };
}

export enum CacheStorageOptions {
    InMemory,
    LocalStorage
}

export enum EntityType {
    Engagement = "Engagement",
    Project = "Project",
    Service = "Service",
    Task = "Task",
    Resource = "Resource",
}

export enum DeliveryType {
    Internal = "Internal",
    Customer = "Customer"
}

export class SearchAttribute {
    public static EngagementName = "EngagementName";
    public static EngagementId = "EngagementId";
    public static ProjectId = "ProjectId";
}

/**
 * Page Change Information for Pagination component
 */
export namespace PageChangeInformation {
    export const PreviousButton: string = "Previous Button";
    export const NextButton: string = "Next Button";
}

/**
 * Key Actions
 * List of Key Action
 */
export namespace KeyAction {
    export const PendingResources: string = "PendingResources";
    export const PendingExpenseReportsPortfolioContext: string = "PendingExpenseReports";
    export const PendingLaborApproval: string = "PendingLaborApproval";
    export const PendingEngagementsForRelease: string = "PendingEngagementsForRelease";
    export const RecieptsDue: string = "RecieptsDue";
    export const PendingMilestonesForConfirmation: string = "PendingMilestonesForConfirmation";
    export const PendingExpenseReportsBasedOnEntityId: string = "PendingExpenseReportsBasedOnEntityId";
    export const PendingLaborBasedOnAssignments: string = "PendingLaborBasedOnAssignments";
    export const EngagementPendingForRelease: string = "EngagementPendingForRelease";
    export const EngagementStartDateLessContractStartDate: string = "EngagementStartDateLessContractStartDate";
    export const ActiveMisalignemdContractualAmendments: string = "ActiveMisalignemdContractualAmendments";
    export const SubconOnboardingStatus: string = "SubconOnboardingStatus";
    export const ExpiringEcifIO: string = "ExpiringEcifIO";
    export const PendingUnitApprovals: string = "PendingUnitApprovals";
}

export namespace EbsState {
    export const Created: string = "Created";
    export const Released: string = "Released";
    export const TechnicallyCompleted: string = "Technically completed";
    export const DECO: string = "DECO";
    export const RECO: string = "RECO";
    export const Closed: string = "Closed";
    export const TechnicallyCompletedShortName: string = "TECO";
}

/**
 * BroadCast Emit Events
 */
export namespace BroadcastEvent {
    export const PortFolioRefresh: string = "PJM.PortfolioRefreshEvent";
    export const SendFailureNotification: string = "PJM.SendFailureNotification";
    export const ShowReleaseAndActivate: string = "PJM.ShowReleaseAndActivate";
    export const RefreshPlanAndForeCast: string = "PJM.RefreshPlanAndForeCast";
    export const ShowPortfolioFeedBack: string = "PJM.ShowPortfolioFeedBack";
    export const ProjectClosureOrchestrationCompleted: string = "ProjectClosureOrchestrationCompleted";
    export const SyncEACToPlanCompleted: string = "SyncEACToPlanCompleted";
    export const SyncToPlanSubmitted: string = "SyncToPlanSubmitted";
    export const AutoGrmMaintenanceRequestSubmitted: string = "AutoGrmMaintenanceRequestSubmitted";
}

/**
 * FxP Logging and Local Storage Application Name
 */
export const FxpLoggingApplicationName: string = "PJM";

/**
 * Partner name registered in fxp for flighting service
 */
export const FxpPartnerApplicationName: string = "Project Management";

export const FeatureNames = ["summary-v2", "ml-forecast-recs", "pnf-tester", "export-excel-v2"];

/**
 * Log Constants
 */
export enum LogEventName {
    EntityTabRefresh = "Entity Tab Refreshed",
    GlobalNavigationChange = "Global Navigation Changed",
    GlobalSearch = "Global Search Clicked",
    GlobalNavigationExpand = "Global Navigation Expanded",
    GlobalNavigationEntityClick = "Global Navigation Entity Clicked",
    HomePageIconClick = "Home Page Icon Clicked",
    InternalEngagementGrmProjectionCreationFailure = "Internal Engagement GrmProjection Creation Failed",
    PortFolioRefresh = "Portfolio Refreshed",
    PortfolioInternalFinancialSummary = "Portfolio Internal Financial Summary Opened",
    PortfolioCustomerFinancialSummary = "Portfolio Customer Financial Summary Opened",
    PortfolioCustomerEngagementsPaginationSet = "Portfolio Customer Engagements Pagination Set",
    PortfolioCustomerEngagementsFilterChanged = "Portfolio Customer Engagements Filter Changed",
    PortfolioInternalEngagementsPaginationSet = "Portfolio Internal Engagements Pagination Set",
    PortfolioNavigationFromRecentlyViewed = "Portfolio Navigation From Recently Viewed",
    PortfolioNavigationFromCustomerEngagementsTile = "Portfolio Navigation From Customer Engagements Tile",
    PortfolioNavigationFromInternalEngagementsTile = "Portfolio Navigation From Internal Engagements Tile",
    PortfolioKeyActionsLinkClick = "Portfolio Key Actions Link Clicked",
    WhatsNewIconClick = "What's New Icon Clicked",
    TeamStructureIconClick = "Team Structure Icon Clicked",
    EngagementSummaryNavigationFromOldToNew = "Navigated from Old to New Engagement Summary",
    EngagementSummaryNavigationFromNewToOld = "Navigated from New to Old Engagement Summary",
    ProjectSummaryNavigationFromOldToNew = "Navigated from Old to New Project Summary",
    ProjectSummaryNavigationFromNewToOld = "Navigated from New to Old Project Summary",
}


/**
 * Application Name Used for Local Storage
 */
export const LocalStorageApplicationName: string = "PJM";
/**
 * Component common prefix
 */
export const ComponentPrefix: string = "Component.";

export const ServicePrefix: string = "Service.";

export const APIPrefix: string = "API.";
/**
 * Global tab for application
 */
export const GlobalSource: string = "Global";
/**
 * Destination page for links
 */
export const DestinationPage: string = "Destination Page";
/**
 * Column name for logs that track sorting engagements list on portfolio
 */
export const SortColumnName: string = "Selected Column Name";
/**
 * Engagement Type- internal or customer
 */
export const EngagementType: string = "Engagement Type";
/**
 * Filter value
 */
export const FilterValue: string = "Selected Filter";
/**
 * Item count for pagination
 */
export const ItemCount: string = "Item Count";

/* Regex that matches any non-FTE aliases */
export const NONFTE_REGEX = /^\s*(([abvt]-)|(partners~)).*/;

export enum LocalStorageKey {
    GlobalSearchHistory = "GlobalSearchHistory",
    TypeAheadSelectedUsers = "TypeAheadSelectedUsers"
}

/* Threshold value that can be allowed for eac and etc time comparisons in
 * audit history as there is slight delay in items being saved
 */
export const PlanForecastAuditThreshold: number = 1000;

/**
 * Ebs request state enum
 */
export enum RequestedState {
    NoStateChangeRequested = "NoStateChangeRequested",
    RequestedForRelease = "RequestedForRelease",
    RequestedForTechnicallyCompleted = "RequestedForTechnicallyCompleted",
    RequestForLocked = "RequestForLocked",
    RequestForUnlocked = "RequestForUnlocked",
    Released = "Released",
    DeliveryComplete = "DeliveryComplete",
    ReadyToClose = "ReadyToClose",
}

/**
 * Constant used for storing feedback entity in session storage
 */
export enum FeedBackEntity {
    Portfolio = "PJM.IsPortfolioFeedbackSurfaced",
    PlanAndForecast = "PJM.PlanAndForecastFeedbackSurfaced",
    FinancialPlanApprovals = "PJM.FinancialPlanApprovalFeedbackSurfaced",
    ManageEBS = "PJM.ManageEBSV2FeedbackSurfaced",
    SummaryV2 = "PJM.SummaryV2FeedbackSurfaced"
}

/**
 * Constant used for whats new tagging
 */
export enum WhatsNewTag {
    New = "New",
    Performance = "Performance",
    Maintenance = "Maintenance"
}

export enum ContactType {
    InvoiceRecipient = "Invoice Recipient +",
    PrimaryBillContact = "Primary Bill Contact",
    CSATContact = "ES CSAT Contact"
}

export namespace DmFxpBannerMessages {
    export const UpdateCSATContactSuccess: string = "CSAT Contact has been successfully updated";
    export const UpdateCSATContactFailure: string = "Error occured in updating CSAT contact. Please try again";
    export const UpdateContactLanguageSuccess: string = "Contact Language request has been successfully initiated";
    export const UpdateContactLanguageFailure: string = "Error occured in updating contact Language. Please try again";
    export const DeleteCSATContactSuccess: string = "CSAT Contact has been successfully deleted";
    export const DeleteCSATContactFailure: string = "Error occured in updating CSAT contact. Please try again";
    export const AtLeastOneContact: string = "Engagement should have at least one CSAT contact.";
    export const ConfirmationMessage: string = "Are you sure do you want to delete CSAT contact: ";
    export const ForecastFlightingMessage: string = "Your Engagement is a part of the new Forecasting Experience. Please use this tab only for Planning activities. Use the new Forecast tab for submitting the forecast on your Engagement.";
    export const PlanFlightingMessage: string = "Your Engagement is a part of the new Plan Experience. Use the new Plan & Forecast tab for Planning activities and submitting the forecast on your Engagement.";
    export const ForecastMigrationInProgressMessage: string = "Your Engagement is being upgraded to the new Forecasting Experience. Thank you for your patience!";
    
}

export enum ContactSortOrderPriority {
    CSATContact = 2,
    PrimaryBillContact = 1,
    InvoiceRecipient = 0
}

export namespace ServiceTypeNames {
    export const Expense = "Expense";
    export const OneTimeFee = "One Time Fee";
    export const RecurringFee = "Recurring Fee";
}

/**
 * Constants used for Accessibility methods
 */
export namespace AccessibilityConstants {
    export const Submit: string = "submit";
    export const CancelRequest: string = "cancelRequest";
    export const CloseButton: string = "closeButton";
    export const ViewApprovals: string = "viewApprovals";
    export const ContinuePopupButton: string = "continuePopupButton";
    export const CloseUpdateButton: string = "closeUpdateButton";
    export const Confirm: string = "confirm";
    export const Financials: string = "financials";
    export const Staffing: string = "staffing";
    export const UpdateProject: string = "updateProject";
    export const UpdateService: string = "updateService";
    export const CloseRejectButton: string = "closeRejectButton";
    export const UpdateTaskSubmit: string = "updateTaskSubmit";
    export const ConfirmMilestoneLink: string = "confirmMilestoneLink";
    export const PlanForecast: string = "planForecast";
    export const ManageEBS: string = "manageEBS";
    export const ManagerSuppliers: string = "managerSuppliers";
    export const Invoices: string = "invoices";
    export const Split: string = "split";
    export const Remove: string = "remove";
    export const Cancel: string = "cancel";
    export const RequestRole: string = "requestRole";
    export const Truncate: string = "truncate";
    export const RmRequest: string = "rmRequest";
    export const SplitPlannedSubmitRequest: string = "splitPlannedSubmitRequest";
    export const SuspendRequest: string = "suspendRequest";
    export const CopyRequest: string = "copyRequest";
    export const TruncateRequest: string = "truncateRequest";
    export const UnsuspendRequest: string = "unsuspendRequest";
    export const UpdateCSAT: string = "updateCSAT";
    export const UpdateCSATContactLanguage: string = "updateCSATContactLanguage";
    export const SaveUserPreferenceLinks: string = "saveUserPreferenceLinks";
    export const HiddenButton: string = "hiddenButton";
    export const EditReturnedRequest: string = "editReturnedRequest";
    export const CloseReturnModal: string = "closeReturnModal";
    export const Milestones: string = "milestones";
    export const Continue: string = "continue";
    export const CloseClinModal: string = "closeClinModal";
    export const ClosePopUp: string = "closePopUp";
    export const ContinueMapping: string = "continueMapping";
    export const CancelModalRequest: string = "cancelModalRequest";
    export const Units: string = "units";
    export const NonProcuredMaterials: string = "nonProcuredMaterials";
    export const CloseModalv2: string = "closeModalv2";
    export const EditDetails: string = "editDetails";
    export const EditTeam: string = "editTeam";
    export const RequestStatusChange: string = "requestStatusChange";
    export const AddTask: string = "addTask";
    export const WhatsNew: string = "whatsNew";
    export const NavBarNotification: string = "navBarNotification";
    export const Refresh: string = "refresh";
    export const PortfolioFeedback: string = "portfolioFeedback";
    export const ScreenReaderTextForEmptyElement: string = "blank";
    export const LoadingCompleteMessageDelay: number = 1000;
}

export enum RoleFullName {
    PrimaryProjectManager = "Lead Project Manager",
    AdditionalPrimaryProjectManager = "Additional Project Manager",
    ProjectManager = "Project Manager / T&E Approver",
    AdditionalProjectManager = "Additional Project Manager",
    PrimaryDomainManager = "Delivery Management Executive",
    RelationshipManager = "Relationship Manager / Invoice Approver",
    AdditionalDomainManager = "Additional Domain DMM",
    UnitSubmitter = "Unit Submitter"
}

export enum RoleShortName {
    PJM = "PJM",
    PPJM = "PPJM",
    ADPPJM = "ADPPJM",
    ADPJM = "ADPJM",
    DMM = "DMM",
    RELMAN = "RELMAN",
    ADMM = "ADMM",
    UnitSubmitter = "ZUNI"
}

export namespace V2ViewName {
    export namespace Engagement {
        export const Summary: string = "engagement_summaryv2";
    }
    export namespace Project {
        export const Summary: string = "project_summaryv2";
    }
}

export const V2ViewsForToggle = ["Summary"];

export const NavHeight = "37px";

export const WbsAuditEventCodes = ["2", "3", "5", "4", "6", "7", "8", "10", "11"] as const;

type WbsAuditEventCode = typeof WbsAuditEventCodes[number];

export const AuditEventNames: Record<WbsAuditEventCode, string> = {
    5: "Engagement status change",
    4: "Engagement Released",
    3: "Additional Primary Project Manager Change ",
    6: "Engagement Created",
    7: "Engagement Name Change",
    8: "Engagement Description Change",
    2: "Primary Project Manager Change",
    10: "Additional Project Manager Added",
    11: "Additional Project Manager Removed"
} as const;
export namespace BusinessTask {
    export const EbsStateChangeTeco = "EbsStateChangeTeco";
    export const ViewChangeRequest = "ViewChangeRequest";
    export const UpdateGoodsReceipt = "UpdateGoodsReceipt";
    export const AddOrRemoveTeamStructure = "AddOrRemoveTeamStructure";
    export const AddOrRemoveTasks = "AddOrRemoveTasks";
    export const EditDescription = "EditDescription";
    export const InitiateForecast = "InitiateForecast";
    export const EbsStateChangeDeco = "EbsStateChangeDeco";
    export const EbsStateChangeReco = "EbsStateChangeReco";
    export const EbsStateReversal = "EbsStateReversal";
}

export const TMContractType: string = "T&M";

export const ExpenseBillableTypes: IBillablityType[] =
    [
        {
            displayName: "Billable",
            code: "EXP001"
        },
        {
            displayName: "Non-Billable",
            code: "EXP002"
        }
    ];

export enum ExpenseBillableCode {
    Billable = "EXP001",
    NonBillable = "EXP002"
}

export namespace PlanVersionType {
    export const Forecast = "Forecast";
    export const CurrentFinancialPlan = "CurrentFinancialPlan";
    export const DeliveryBaseline = "DeliveryBaseline";
}

export namespace StaffVersionType {
    export const Forecast = "Forecast";
    export const Planned = "Planned";
    export const Staffed = "Staffed";
    export const Actuals = "Actuals";
}

export namespace FcrTabs {
    export const Labor: IDmTab = {
        displayName: "Labor",
        id: ResourceType.Labor,
        isActive: true,
        order: 4
    };
    export const Unit: IDmTab = {
        displayName: "Units",
        id: ResourceType.Unit,
        isActive: false,
        order: 1
    };
    export const SubconFF: IDmTab = {
        displayName: "SubconFF",
        id: ResourceType.SubconFF,
        isActive: false,
        order: 3
    };
    export const Expense: IDmTab = {
        displayName: "Expense",
        id: ResourceType.Expense,
        isActive: false,
        order: 2
    };
}

/**
 * Feature Names for logging
 */
export namespace Feature {
    export const ViewChangeRequest: string = "ViewChangeRequest";
    export const ViewInternalEngagementSummary: string = "ViewInternalEngagementSummary";
    export const ViewInternalEngagementFinancials: string = "ViewInternalEngagementFinancials";
    export const ViewInternalEngagementStaffing: string = "ViewInternalEngagementStaffing";
    export const CreateFinancialChangeRequest: string = "CreateFinancialChangeRequest";
    export const CreateInternalEngagement: string = "CreateInternalEngagement";
}

export enum PurchaseOrderType {
    Material = "MAT",
    TM = "T&M",
    FixedFee = "FFS",
    Expense = "EXP"
}

export namespace BulkMaintenanceStatus {
    export const InProgress: string = "In Progress";
    export const Success: string = "Success";
    export const Failed: string = "Failed";
}

export namespace ProjectUserStatusTypes {
    export const ECIF: string = "ECIF";
    export const ECIFPartial: string = "ECIFPartial";
    export const JPM: string = "JPM";
    export const VLEA: string = "VLEA";
    export const Prepay: string = "PRP";
    export const DeletedProject: string = "MDL";
}

export namespace ProjectClosureModelEventTypes {
    export const OnSubmit: string = "onSubmit";
    export const OnCancel: string = "onCancel";
    export const OnRefresh: string = "onRefresh";
}

export namespace ProjectClosureSubmitFormData {
    export const File: string = "file";
    export const PJCStatusContract: string = "pjcStatusContract";
    export const OrchestrationId: string = "orchestrationId";
}

export enum UnitSubmissionStatus {
    PendingUnits = 1
}

export enum ForecastNotificationType {
    SyncEACStatusChange
}

export namespace OperatingStatus {
    export const InProgress: string = "InProgress";
    export const Success: string = "Success";
    export const Failed: string = "Failed";
}
